import React, { useMemo } from 'react'
import {
  Box,
  Divider,
  List,
} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'
import AssessmentIcon from '@mui/icons-material/Assessment'
import ApartmentIcon from '@mui/icons-material/Apartment'
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone'
import InventoryIcon from '@mui/icons-material/Inventory';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import NavItem, { IItem } from 'components/nav.item'
import authService from 'services/auth.service'
import { useNavigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { drawerWidth } from 'layouts/main.layout'

interface IDashboardSidebar {
  open: boolean;
  onClose?: () => void
}
const getAllowedRoutes = (routes: Array<IItem>, role: string): any[] => {
  const results = []
  for (let key in routes) {
    const route = routes[key]
    if (route.roles?.includes(role)) {
      if (route.items) {
        results.push({ ...route, items: getAllowedRoutes(route.items, role) })
        continue
      } else if (route.roles?.includes(role)) {
        results.push(route)
      }
    }
  }
  return results
}

const items: IItem[] = [
  {
    name: 'main.dashboard',
    path: '/',
    label: 'Dashboard',
    Icon: <HomeTwoToneIcon fontSize="small" />,
    roles: ['affiliate', 'business']
  },
  {
    name: 'products',
    label: 'Products',
    path: '/products',
    Icon: <InventoryIcon fontSize="small" />,
    roles: ['affiliate', 'business'],
  },
  {
    name: 'payments',
    path: '/payments',
    label: 'Payments',
    Icon: <AssessmentIcon fontSize="small" />,
    roles: ['affiliate']
  },
  {
    name: 'business',
    label: 'Businesses',
    path: '/business',
    Icon: <ApartmentIcon fontSize="small" />,
    roles: ['business'],
  },
  {
    name: 'invoices',
    path: '/invoices',
    label: 'Invoices',
    Icon: <FileCopyOutlinedIcon fontSize="small" />,
    roles: ['business']
  },
  {
    name: 'reports',
    label: 'Reports',
    Icon: <SettingsApplicationsIcon fontSize="small" />,
    roles: ['business', 'affiliate'],
    items: [
      {
        name: 'overview.report',
        path: '/reports/overview',
        label: 'Overview',
        Icon: <MonetizationOnTwoToneIcon fontSize="small" />,
        roles: ['affiliate', 'admin']
      },
      {
        name: 'sales.chart.report',
        path: '/reports/sales/chart',
        label: 'Sales Report',
        Icon: <PersonIcon fontSize="small" />,
        roles: ['business', 'affiliate', 'admin']
      },
    ],
  },
  {
    name: 'settings',
    label: 'Settings',
    Icon: <SettingsApplicationsIcon fontSize="small" />,
    roles: ['business', 'affiliate'],
    items: [
      {
        name: 'disbursement.methods',
        path: '/settings/disbursement-methods',
        label: 'Get paid',
        Icon: <MonetizationOnTwoToneIcon fontSize="small" />,
        roles: ['affiliate']
      },
      {
        name: 'profile',
        path: '/profile',
        label: 'My Profile',
        Icon: <PersonIcon fontSize="small" />,
        roles: ['business', 'affiliate', 'admin']
      },
    ],
  },
]
const DashboardSidebar = (props: IDashboardSidebar) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const role = auth && auth.currentRole ? auth.currentRole : ''

  const handleSignout = () => {
    authService.logout().then(() => {
      navigate('/');
      window.location.reload()
    })
  }

  const routes = useMemo(() => getAllowedRoutes(items, role), [role])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: drawerWidth
        }}
      >

        <List>
          {(routes || []).map((item: any) => (
            <NavItem key={item.name} item={item} />
          ))}
        </List>
        <Divider />
        <List
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <NavItem
            item={{
              label: 'Logout',
              Icon: <PowerSettingsNewIcon />,
              path: '/logout',
              onClick: handleSignout,
            }}
          />
        </List>
      </Box>
    </>
  )
}

export default DashboardSidebar
