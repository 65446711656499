import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton, StepButton, TextField } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import ApiRequest from 'services/api.request';
import ModalComponent from 'components/Modal';
import Swal from 'sweetalert2';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

function IntergrationForm({ businessId, callback }: { businessId: string | number, callback?: (...rest: any) => void }) {
    const [host, setHost] = useState('')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHost(event.target.value);
    };

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        const regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
        if (regexp.test(host)) {
            const payload = {
                businessId,
                host
            }
            ApiRequest.post('integrations/', payload).then(({ data }) => {
                if (callback) {
                    callback(data)
                }
            })

        } else {
            Swal.fire({
                text: 'Not a valid domain',
                icon: 'warning',
            })
        }
    };
    return (
        <Box display={'flex'} flexDirection="column">
            <TextField label="Website domain name" helperText="www.domain.com" onChange={handleChange} />
            <Button onClick={handleSubmit} variant='contained'>Done</Button>
        </Box>
    )
}

type TIntegration = {
    id: number
    host: string
    key: string
    status: string
    createdAt?: Date
    updatedAt?: Date
}
export default function IntegrationInstructions({ businessId }: { businessId?: string | number }) {
    const [activeStep, setActiveStep] = useState(0);
    const [integration, setIntegration] = useState<TIntegration>()
    useEffect(() => {
        if (businessId) {
            ApiRequest.get(`integrations/?businessId=${businessId}`).then(({ data }) => {
                setIntegration(data?.rows[0])
            })
        }

    }, [businessId])

    const createClick = () => ModalComponent.show({
        component: IntergrationForm,
        title: 'Get API Key',
        noDelay: true,
        passProps: {
            businessId,
            callback: (data: any) => {
                setIntegration(data)
                ModalComponent.hide()
            }
        },
    })

    const copyMarkettingUrl = () => {
        if (integration) {
            navigator.clipboard.writeText(integration.key);
            Swal.fire({
                title: 'Copied!',
                icon: 'info',
            })
        }

    }

    const codeString1 = `
// Paste the following code snippet into the <head> section of your website:
<script>
window.af = window.af || [];
function atag() {
  af.push(arguments);
}

atag('js', new Date());
atag('api-key', '${integration?.key}');
</script>
`

    const codeString2 = `
var data = {
    totalValue: 300,
    orderItems: [
      {
        id: 3,
        price: 50,
        quantity: 3,
      },
      {
        id: 5,
        price: 50,
        quantity: 3,
      },
    ],
  };
  
  atag('event','sale',data);
`

    return (
        <Stepper activeStep={activeStep} orientation="vertical">
            <Step index={0}>
                <StepButton
                    optional={(<Typography variant="caption">Business integration credentials</Typography>)}
                    onClick={() => setActiveStep(0)}
                >
                    Integration Settings
                </StepButton>
                <StepContent>

                    {integration ? <>
                        <Box display={'flex'} justifyContent="space-between">
                            <Typography variant='body2'>Business Key <Typography variant='caption' paragraph>{integration?.key}</Typography> </Typography>
                            <IconButton color='secondary' disableRipple onClick={copyMarkettingUrl}> <ContentCopy /></IconButton>
                        </Box>
                        <Typography variant='body2'>Status</Typography>
                        <Typography variant='caption' paragraph>{integration?.status}</Typography>


                        <Box display={'flex'} justifyContent="space-between">
                            <Button color='warning'>Disable</Button>
                            <Button color='error' variant='contained'>Remove</Button>
                        </Box>
                    </> :
                        <Button onClick={createClick} color='info' variant='outlined'>Start Integration</Button>}


                </StepContent>
            </Step>
            <Step index={1} disabled={!integration}>
                <StepButton
                    optional={(<Typography variant="caption">How to setup Adfiti on your website</Typography>)}
                    onClick={() => setActiveStep(1)}
                >
                    Usage
                </StepButton>
                <StepContent>
                    <Box display={'flex'} flexDirection='column'>
                        <SyntaxHighlighter language="javascript" style={dark}>
                            {codeString1}

                        </SyntaxHighlighter>
                        <Typography paragraph>
                            <Typography paragraph variant='body2'>* Ensure the script is accessible on all pages.</Typography>

                            <Typography paragraph variant='h6'>How to Add Sale Tracking Code to the Order Success Page</Typography>

                            <Typography>To track your sales and monitor the performance of your affiliate program, it's important to add sale tracking code to your order success page. <br />
                                Follow these simple steps to add the code:</Typography>

                            <Typography paragraph>Open the order success page on your website. This is the page that your customers see after they complete a purchase.</Typography>

                            <Typography paragraph>Alternatively, you can trigger this code manually by calling it whenever a successful purchase is made.<br />
                                This is a good choice especially for Single Page Applications or where no redirections to a success page is used in the checkout process.
                            </Typography>

                            <Typography paragraph>Copy the following code:</Typography>
                        </Typography>
                        <SyntaxHighlighter language="javascript" style={dark}>
                            {codeString2}

                        </SyntaxHighlighter>

                        <Typography paragraph>
                            Replace the sample data in the code with the dynamic data of the sale.
                        </Typography>
                        <SyntaxHighlighter language="javascript" style={dark}>
                            {
                                `
'totalValue' - the total value of the sale

'orderItems' - details of the products that were purchased.

'orderId' - order ID for this specific sale.
                                `
                            }

                        </SyntaxHighlighter>
                        <Typography paragraph> Paste the modified code into the head section of your order success page.</Typography>

                        <Typography paragraph> That's it! Now your affiliate program will be able to track sales and report on the performance of your program.</Typography>

                    </Box>
                </StepContent>
            </Step>
        </Stepper>
    );
}

