import {
    Grid,
    Box,
    Typography,
    Stack,
    Link as MuiLink,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FC } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from 'components/FormInput';
import { ReactComponent as GoogleLogo } from 'assets/google.svg';
import styled from '@emotion/styled';
import authService from 'services/auth.service';
import Swal from 'sweetalert2';
import { useState } from 'react';
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

// 👇 Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
    text-decoration: none;
    color: #3683dc;
    &:hover {
      text-decoration: underline;
      color: #5ea1b6;
    }
  `;

// 👇 Styled Material UI Link Component
export const OauthMuiLink = styled(MuiLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f6f7;
    border-radius: 1;
    padding: 0.6rem 0;
    column-gap: 1rem;
    text-decoration: none;
    color: #393e45;
    font-weight: 500;
    cursor: pointer;
  
    &:hover {
      background-color: #fff;
      box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
    }
  `;
// 👇 Login Schema with Zod
const loginSchema = object({
    username: string().min(1, 'Username is required'),
    password: string()
        .min(1, 'Password is required'),
});

// 👇 Infer the Schema to get the TS Type
type ILogin = TypeOf<typeof loginSchema>;

const LoginForm: FC = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setAuth } = useAuth();
    // 👇 Default Values
    const defaultValues: ILogin = {
        username: '',
        password: '',
    };

    // 👇 The object returned from useForm Hook
    const methods = useForm<ILogin>({
        resolver: zodResolver(loginSchema),
        defaultValues,
    });

    // 👇 Submit Handler
    const onSubmitHandler: SubmitHandler<ILogin> = (values: ILogin) => {
        setLoading(true)
        authService.login(values)
            .then(({ data }: any) => {
                if (data.accessToken) {
                    ReactGA.set({ userId: data.id })
                }
                setAuth(data)
                navigate('/')
                window.location.reload()
            })
            .catch((resError) => {
                console.log("Error", resError)
                Swal.fire({
                    text: resError?.error || 'Login error',
                    icon: 'warning',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Try again',
                    allowOutsideClick: false,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    };

    // 👇 JSX to be rendered
    return (
        <FormProvider {...methods}>
            <Grid
                container
                sx={{
                    boxShadow: { sm: '0 0 5px #ddd' },
                    py: '6rem',
                    px: '1rem',
                }}
            >
                <Grid
                    item
                    container
                    justifyContent='space-between'
                    rowSpacing={5}
                    sx={{
                        maxWidth: { sm: '45rem' },
                        marginInline: 'auto',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ borderRight: { sm: '1px solid #ddd' } }}
                    >
                        <Box
                            display='flex'
                            flexDirection='column'
                            component='form'
                            noValidate
                            autoComplete='off'
                            sx={{ paddingRight: { sm: '3rem' } }}
                            onSubmit={methods.handleSubmit(onSubmitHandler)}
                        >
                            <Typography
                                variant='h6'
                                component='h1'
                                sx={{ textAlign: 'center', mb: '1.5rem' }}
                            >
                                Log into your account
                            </Typography>

                            <FormInput
                                label='Enter email or Phone number'
                                type='text'
                                name='username'
                                focused
                                required
                            />
                            <FormInput
                                type='password'
                                label='Password'
                                name='password'
                                required
                                focused
                            />

                            <LoadingButton
                                loading={loading}
                                type='submit'
                                variant='contained'
                                sx={{
                                    py: '0.8rem',
                                    mt: 2,
                                    width: '80%',
                                    marginInline: 'auto',
                                }}
                            >
                                Login
                            </LoadingButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant='h6'
                            component='p'
                            sx={{
                                paddingLeft: { sm: '3rem' },
                                mb: '1.5rem',
                                textAlign: 'center',
                            }}
                        >
                            Log in with another provider:
                        </Typography>
                        <Box
                            display='flex'
                            flexDirection='column'
                            sx={{ paddingLeft: { sm: '3rem' }, rowGap: '1rem' }}
                        >
                            <OauthMuiLink href='' aria-disabled>
                                <GoogleLogo style={{ height: '2rem' }} />
                                Google
                            </OauthMuiLink>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Stack sx={{ mt: '3rem', textAlign: 'center' }}>
                        <Typography sx={{ fontSize: '0.9rem', mb: '1rem' }}>
                            Need an account?{' '}
                            <LinkItem to='/signup'>Sign up here</LinkItem>
                        </Typography>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            Forgot your{' '}
                            <LinkItem to='/forgotPassword'>password?</LinkItem>
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default LoginForm;
