import { Box, Button, Card, CardContent, Menu, MenuItem, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Edit as EditIcon, KeyboardArrowDown, Add as AddIcon } from '@mui/icons-material';
import MainLayout from 'layouts/main.layout';
import businessService from 'services/business.service';
import BusinessForm from 'modules/business/business.form';
import { IBusiness } from 'modules/business/business.form';
import IntegrationInstructions from 'modules/business/business.integration';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export default function ViewBusiness() {
  const [business, setBusiness] = useState<IBusiness>()
  const { id } = useParams()
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (id) {
      businessService.get(id).then(({ data }) => setBusiness(data as IBusiness))
    }

  }, [id])
  const businessFormProps = {
    item: business
  }
  return (
    <MainLayout>
      <Box display={'flex'} justifyContent='space-between'>
        <NewComponentsMenu />
        <ReportsMenu />
      </Box>
      <Card>
        <CardContent>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="my profile tabs">
                <Tab label="General" value="1" />
                <Tab label="Settings" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1"> {business ? <BusinessForm {...businessFormProps} /> : null}</TabPanel>
            <TabPanel value="2">
              <IntegrationInstructions businessId={business?.id} />
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>


    </MainLayout>
  );
}

function NewComponentsMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={<AddIcon />}
      >
        New
      </Button>
      <Menu
        id="add-components-menu"
        MenuListProps={{
          'aria-labelledby': 'add-component-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Product
        </MenuItem>
      </Menu>
    </div>
  );
}

function ReportsMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        color="success"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        Reports
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Sales report
        </MenuItem>
      </Menu>
    </div>
  );
}
