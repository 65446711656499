// @ts-nocheck
import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery
} from '@mui/material'
import {
  styled, useTheme,
} from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string
  children: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}


const Dispatcher = {
  events: {},
  subscribe(event, callback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  },

  broadcast(event: string, args: undefined) {
    if (this.events[event] && this.events[event].length) {
      this.events[event].map((callback) => {
        return callback(args)
      })
    }
  },
}

export default class ModalComponent extends React.Component {
  static show(args) {
    Dispatcher.broadcast('showModal', args)
  }

  static hide() {
    Dispatcher.broadcast('hideModal')
  }

  static hideAll() {
    Dispatcher.broadcast('hideAllModal')
  }

  static replace(args) {
    Dispatcher.broadcast('replaceModal', args)
  }

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      queue: [],
    }
  }

  componentDidMount() {
    Dispatcher.subscribe('showModal', (args) => {
      let { queue } = this.state
      const { id } = this.state

      if (args.id) {
        if (args.id === id) {
          return false
        }

        const modalIndex = queue.findIndex((modal) => modal.id === args.id)
        if (modalIndex >= 0) {
          queue = queue.slice(0, modalIndex)
        }
      }

      // queue.push({
      //   content: <Loader />,
      //   ...args,
      // })

      this.setState({
        show: true,
        queue,
        id: args.id,
        title: args.title,
        className: args.className,
        onCancel: args.onCancel,
      })

      if (!args.content && args.component) {
        setTimeout(
          () => {
            queue.pop()
            queue.push({
              content: <args.component {...args.passProps} />,
              ...args,
            })

            this.setState({
              show: true,
              queue,
            })
          },
          args.noDelay ? 0 : 400
        )
      }
      return null
    })

    Dispatcher.subscribe('hideModal', () => {
      // this may run outside order of invocation - try using `setTimeout(() => Modal.hide(), 0)`
      // TODO FWA-3993: Modal show/hide queue is asynchronous and executes out-of-order
      this.handleHide()
    })

    Dispatcher.subscribe('hideAllModal', () =>
      this.setState({
        show: false,
        queue: [],
      })
    )

    Dispatcher.subscribe('replaceModal', (args) => {
      const { queue } = this.state
      queue.pop()
      queue.push(args)
      setTimeout(() => {
        this.setState({
          show: true,
          queue,
          id: args.id,
          onCancel: args.onCancel,
        })
      }, 0)
    })
  }

  handleHide = () => {
    const { onCancel, queue } = this.state
    if (onCancel) {
      onCancel()
    }
    queue.pop()
    if (queue.length > 0) {
      const current = queue[queue.length - 1]
      setTimeout(() => {
        this.setState({
          show: true,
          queue,
          id: current.id,
          onCancel: current.onCancel,
        })
      }, 0)
      return false
    }
    setTimeout(() => {
      this.setState({
        show: false,
        id: null,
        queue: [],
      })
    }, 0)
    return true
  }

  render() {
    const { queue, show, className, title } = this.state

    return (
      <DialogWrapper
        show={show}
        onClose={this.handleHide}
        className={`${className || ''}`}
        title={title}
      >
        {queue.map((q, idx) => (
          <DialogContent
            key={idx}
            dividers
            sx={{ display: idx === queue.length - 1 ? 'block' : 'none' }}
          >
            {q.content}
          </DialogContent>
        ))}
      </DialogWrapper>
    )
  }
}

const DialogWrapper = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { show, children, onClose, title } = props
  return (
    <BootstrapDialog
      open={show}
      onClose={onClose}
      scroll="body"
      // disableScrollLock
      fullScreen={fullScreen}
      fullWidth
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      {children}
    </BootstrapDialog>
  )
}
