import { grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    /* primary: {
      ...amber,
      ...(mode === 'dark' && {
        main: amber[300],
      }),
    }, */
    /* ...(mode === 'dark' && {
      background: {
        default: deepOrange[900],
        paper: deepOrange[900],
      },
    }), */
    text: {
      ...(mode === 'light'
        ? {
          primary: grey[900],
          secondary: grey[800],
        }
        : {
          primary: '#fff',
          secondary: grey[500],
        }),
    },
  },
});

/* const theme = createTheme({
  palette: {
    background: {
      default: "#f3f7fa"
    },
    mode: 'dark',
    primary: {
      main: '#272e3d'
    }
  }
}); */

export default getDesignTokens