import React, { useCallback, useContext, useEffect, useState } from 'react';

import MainLayout from 'layouts/main.layout';
import MainCard from 'components/MainCard';
import formatCurrency from 'helpers/formatCurrency';
import { Box, Tab, Theme, Typography, useMediaQuery } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import SalesInprogress from './sales.inprogress';
import ApiRequest from 'services/api.request';
import paymentService from 'services/payment.service';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import { AuthContext } from 'App';
import PaymentList from 'modules/payment/payment.list';
dayjs.extend(weekday)

const BadgeLabel = ({ title, caption, description, value }: {
    title: string
    caption?: string
    description?: string
    value: number
}) => {
    return (
        <Box sx={{ textAlign: 'left', textTransform: 'none' }}>
            <Typography variant='subtitle1'>{title}</Typography>
            <Typography variant='h6'>{formatCurrency(value)}</Typography>
            <Typography variant='caption'>{caption}</Typography>
        </Box>
    )
}
export default function ReportOverview() {
    const [value, setValue] = useState('1');
    const [stats, setStats] = useState<Record<string, number>>({
        inProgress: 0,
        inReview: 0,
        pending: 0,
        available: 0
    })
    const { auth: { id: userId } } = useContext(AuthContext)
    const [query] = useState<Record<string, number | string | undefined>>({
        ...(userId && { userId }),
        limit: 1
    })
    const { data: payments } = usePaginatedSearch(paymentService, query)
    const lastPayment = payments?.rows[0]

    useEffect(() => {
        ApiRequest.get('auth/account-balance')
            .then(({ data }) => setStats(state => ({ ...state, available: data?.balance || 0 })))
            .catch(e => console.error(e))
    }, [])

    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const updateStats = useCallback((key: string) => (val: number) => {
        setStats(state => ({ ...state, [key]: val }))
    }, [])

    return (
        <MainLayout>
            <MainCard sx={{ mt: 1.75, border: '1px solid lightgrey' }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                scrollButtons={isSmallScreen}
                                allowScrollButtonsMobile
                                variant={isSmallScreen ? 'scrollable' : 'fullWidth'}
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab label={<BadgeLabel title='In Progress' value={stats.inProgress} />} value="1" />
                                <Tab label={<BadgeLabel title='In Review' value={stats.inReview} />} value="2" />
                                <Tab label={<BadgeLabel title='Pending' value={stats.pending} />} value="3" />
                                <Tab label={<BadgeLabel title='Available' value={stats.available} caption={lastPayment ? `Last payment: ${formatCurrency(lastPayment.amount)}` : ''} />} value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Typography variant='subtitle2'>Salesheet for {dayjs().weekday(1).format('MMM DD')} - {dayjs().weekday(7).format('DD')}  (this week) in progress</Typography>
                            <SalesInprogress updateValue={updateStats('inProgress')} stage="in-progress" />
                        </TabPanel>
                        <TabPanel value="2">
                            <Typography variant='subtitle2'>Salesheet for {dayjs().weekday(-6).format('MMM DD')} - {dayjs().weekday(0).format('MMM DD')}  (last week) in review</Typography>
                            <SalesInprogress updateValue={updateStats('inReview')} stage="in-review" />
                        </TabPanel>
                        <TabPanel value="3">
                            <SalesInprogress updateValue={updateStats('inReview')} stage="in-review" />
                        </TabPanel>
                        <TabPanel value="4"><PaymentList /></TabPanel>
                    </TabContext>
                </Box>
            </MainCard>
        </MainLayout >
    );
}