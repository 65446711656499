import BaseDataService from "./base.service";

export class CampaignDataService extends BaseDataService {
    constructor() {
        super({ name: 'campaigns' })
    }
}

const service = new CampaignDataService();

export default service