import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { green } from '@mui/material/colors';
import formatCurrency from 'helpers/formatCurrency';
import ModalComponent from 'components/Modal';
import MpesaForm from 'modules/paymen-method/mpesa.form';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import paymentMethodService from 'services/payment.method.service';
import { PaymentMethod } from 'types/payment.method.type';
import Swal from 'sweetalert2';
import ContextMenu from 'components/menu.component';
import PaymentList from 'modules/payment/payment.list';
import Loader from 'components/loader';
import paymentService from 'services/payment.service';
import { AuthContext } from 'App';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

export default function PaymentDetails() {
    const { data, loading, refetch } = usePaginatedSearch(paymentMethodService)
    const { auth: { id: userId } } = useContext(AuthContext)
    const [query, setQuery] = useState<Record<string, number | string | undefined>>({
        ...(userId && { userId }),
        limit: 1
    })
    const { data: payments } = usePaginatedSearch(paymentService, query)
    const lastPayment = payments?.rows[0]
    useEffect(() => {
        setQuery((state) => {
            return {
                ...state,
                userId
            }
        })
    }, [userId])


    const addMethodClick = () => ModalComponent.show({
        component: MpesaForm,
        title: 'Setup Mpesa Payment',
        noDelay: true,
    })

    const viewPaymentsClick = () => ModalComponent.show({
        component: PaymentList,
        title: 'Payment History',
        noDelay: true,
    })

    const actions = [
        {
            label: 'Edit',
            roles: ['affiliate'],
            run: (item: PaymentMethod) => {
                ModalComponent.show({
                    component: MpesaForm,
                    title: 'Edit Mpesa Account Details',
                    noDelay: true,
                    passProps: {
                        item,
                        callback: () => {
                            refetch?.()
                        }
                    },
                })
            },
        }, {
            label: 'Remove ',
            roles: ['affiliate'],
            run: (item: PaymentMethod) => {
                const { id } = item
                Swal.fire({
                    title: 'Are you sure?',
                    text: "This payment method will be deleted permanently. This action cannot be reversed.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed && id) {
                        paymentMethodService.delete(id).then(() => {
                            refetch?.()
                        })
                    }
                })
            },
        },
    ]
    return (
        <Card>
            <CardHeader title="Payment details" />
            <CardContent>
                <Grid container spacing={2} pb={2}>
                    <Grid item xs={12} sm={8} md={8} lg={9}>
                        <Typography variant='subtitle1'>Last Payment</Typography>
                        <Typography variant='body2'>{lastPayment ? `${formatCurrency(lastPayment?.amount)} on ${dayjs(lastPayment?.createdAt).format('LL hh:mm a')} to ${lastPayment?.payment_method?.name} - Account ending in ${lastPayment?.payment_method?.ac_no_last_digits}`: 'No payment history available'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <Button onClick={viewPaymentsClick} sx={{ float: "right" }} variant='outlined'>View payments</Button>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} py={2}>
                    <Grid item xs={12} sm={8} md={8} lg={9}>
                        <Typography variant='subtitle1'>Schedule</Typography>
                        <Typography variant='body2'>Quarterly (next on Mar 27) to
                            Wire Transfer (USD) - Account ending in
                            6497.</Typography>
                        <Typography variant='caption'>Only when balance is $1,000.00 or more</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <Button sx={{ float: "right"}} variant='outlined'>Edit schedule</Button>
                    </Grid>
                </Grid> */}
                <Divider />
                <Box display='flex' my={2} justifyContent="space-between">
                    <Typography variant='subtitle1'>Payment Methods</Typography>
                    <Button onClick={addMethodClick} variant='outlined'>Add method</Button>
                </Box>
                {loading ? <Loader /> : <List component="div">
                    {(data?.rows || []).map(item => (
                        <React.Fragment key={item?.id}>
                            <ListItem
                                secondaryAction={
                                    <ContextMenu
                                        items={actions}
                                        value={item}
                                    />
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: green[500] }} src="https://www.safaricom.co.ke/images/M-PESA.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${(item?.name || '').toUpperCase()} ending in ${item?.ac_no_last_digits}`}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    ))}

                </List>}

            </CardContent>
        </Card>
    );
}
