import React, { useMemo } from 'react'
import {
  List,
} from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone'
import InventoryIcon from '@mui/icons-material/Inventory';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import useAuth from 'hooks/useAuth'
import NavItem, { IItem } from 'components/nav.item'
import authService from 'services/auth.service'

interface IDashboardSidebar {
  open: boolean;
  onClose?: () => void
}
const getAllowedRoutes = (routes: Array<IItem>, role: string): any[] => {
  const results = []
  for (let key in routes) {
    const route = routes[key]
    if (route.items) {
      results.push({ ...route, items: getAllowedRoutes(route.items, role) })
      continue
    } else if (route.roles?.includes(role) || route.roles?.includes('*')) {
      results.push(route)
    }
  }
  return results
}

const items: IItem[] = [
  {
    name: 'products',
    label: 'Products',
    path: '/products',
    Icon: <InventoryIcon fontSize="small" />,
    roles: ['affiliate', 'business'],
  },
  {
    name: 'invoices',
    path: '/invoices',
    label: 'Invoices',
    Icon: <FileCopyOutlinedIcon fontSize="small" />,
    roles: ['business']
  },
  {
    name: 'reports',
    label: 'Reports',
    Icon: <SettingsApplicationsIcon fontSize="small" />,
    roles: ['business', 'affiliate'],
    items: [
      {
        name: 'overview.report',
        path: '/reports/overview',
        label: 'Overview',
        Icon: <MonetizationOnTwoToneIcon fontSize="small" />,
        roles: ['affiliate', 'admin']
      },
      {
        name: 'sales.chart.report',
        path: '/reports/sales/chart',
        label: 'Sales Report',
        Icon: <PersonIcon fontSize="small" />,
        roles: ['business', 'affiliate', 'admin']
      },
    ],
  },
  {
    name: 'settings',
    label: 'Settings',
    Icon: <PersonIcon fontSize="small" />,
    roles: ['business', 'affiliate'],
    items: [
      {
        name: 'disbursement.methods',
        path: '/settings/disbursement-methods',
        label: 'Get paid',
        Icon: <MonetizationOnTwoToneIcon fontSize="small" />,
        roles: ['affiliate']
      },
      {
        name: 'profile',
        path: '/profile',
        label: 'My Profile',
        Icon: <PersonIcon fontSize="small" />,
        roles: ['business', 'affiliate', 'admin']
      },
      {
        name: 'business',
        label: 'My Businesses',
        path: '/business',
        Icon: <ApartmentIcon fontSize="small" />,
        roles: ['business'],
      },
      {
        label: 'Logout',
        Icon: <PowerSettingsNewIcon color='warning' />,
        path: '/logout',
        roles: ['*'],
        onClick: () => {
          authService.logout().then(() => {
            window.location.href = '/'
            window.location.reload()
          })
        },
      }
    ],
  },
]
const TopMenuItems = (props: IDashboardSidebar) => {
  const { auth } = useAuth();
  const role = auth && auth.currentRole ? auth.currentRole : ''

  const routes = useMemo(() => getAllowedRoutes(items, role), [role])

  return (
    <>

      <List sx={{ display: 'flex' }}>
        {(routes || []).map((item: any) => (
          <NavItem key={item.name} item={item} />
        ))}
      </List>
    </>
  )
}

export default TopMenuItems
