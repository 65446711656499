import http from "../services/api.request";
import IBusinessData from "types/business.type"
import BaseDataService from "./base.service";

export class BusinessDataService extends BaseDataService {
    constructor() {
        super({ name: 'business' })
    }

    findByName(name: string) {
        return http.get<Array<IBusinessData>>(`/business?name=${name}`);
    }
}

const service = new BusinessDataService();

export default service