import React from 'react';
import PaymentList from 'modules/payment/payment.list';

import MainLayout from 'layouts/main.layout';
import { Typography } from '@mui/material';

export default function Payments() {
  return (
    <MainLayout>
      <Typography variant='h6' paragraph>Transaction History</Typography>
      <PaymentList />
    </MainLayout>
  );
}