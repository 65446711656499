import BaseDataService from "./base.service";

export class SaleDataService extends BaseDataService {
    constructor() {
        super({ name: 'sales' })
    }
}

const service = new SaleDataService();

export default service