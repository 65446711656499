import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, AlertTitle, Box, Button, Card, CardContent, CardHeader, Chip, Divider, InputAdornment, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import Loader from 'components/loader'
import invoiceService from 'services/invoice.service';
import formatCurrency from 'helpers/formatCurrency';
import ModalComponent from 'components/Modal';

export default function InvoiceList() {
  const { data, loading, error } = usePaginatedSearch(invoiceService)

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error getting data</AlertTitle>
        {error}
      </Alert>
    )
  }
  if (loading) {
    return <Loader />
  }

  if (!data) {
    return (
      <Typography variant='subtitle1'>Data not available yet</Typography>
    )
  }

  const { rows } = data
  const handlePayClick = (invoice: Record<string, any>) => {
    ModalComponent.show({
      component: MakePayment,
      title: 'Make Payment',
      noDelay: true,
      passProps: {
        amount: invoice?.totalAmount,
        referenceId: invoice?.id
      },
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="business table">
        <TableHead>
          <TableRow>
            <TableCell>Ref ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{formatCurrency(row.totalAmount)}</TableCell>
              <TableCell align="right"><Chip label={row.status} variant='filled' color={row.status === 'paid' ? 'success' : 'warning'} /></TableCell>
              <TableCell align="right" sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {row.status === 'pending' ? <Button variant='contained' onClick={() => handlePayClick(row)} color='primary'>Pay now</Button> : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const MakePayment = ({ amount, referenceId }: { amount: number, referenceId: string | number }) => {
  return (
    <Card>
      <CardHeader title="Mpesa" subheader='Use the following mpesa options to make your payment' />
      <CardContent>
        <div>
          <Box>
            <Typography variant='h6'>Option 1 - Pay Bill</Typography>
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Go to the M-pesa Menu."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Select Pay Bill."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Enter Business No."
                  secondary="220220"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Enter Account No."
                  secondary={referenceId}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Enter the Amount."
                  secondary={amount}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Enter your M-Pesa PIN then send."
                />
              </ListItem>
            </List>
            <Divider />
            <Box my={2}>
              <Typography variant='h6' paragraph>Option 2 - Instant Payment</Typography>
              <Typography variant='body1'>
                Enter your M-PESA registered phone number below and click Pay Now then check your mobile phone handset for an instant payment request from Safaricom M-PESA.
                M-PESA Registered Phone Number
              </Typography>
              <TextField
                label="Mpesa registered Phone Number"
                id="outlined-start-adornment"
                fullWidth
                sx={{ mt: 1 }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+254</InputAdornment>,
                }}
              />
            </Box>

          </Box>
          <Typography variant='subtitle1'>Notes</Typography>
          <Typography paragraph>Please ensure you enter the exact amount indicated in your invoice to avoid inconveniences</Typography>
            <Typography paragraph variant='subtitle2'>Thank-you for your business</Typography>
        </div>
      </CardContent>
    </Card>
  )
}