import { Box, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { number, object, string, TypeOf, custom } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from 'components/FormInput';
import Swal from 'sweetalert2';
import productService from 'services/product.service';
import useCategoryList from 'hooks/useCategoryList';

// 👇 Product Schema with Zod
const productSchema = object({
    name: string().min(1, 'Business name is required').max(200),
    categoryId: number().min(1, 'Category is required'),
    price: number().min(1, 'Price is required'),
    description: string().min(11, 'Description is required').max(1300),
    note: string().max(1300),
    commission: number().min(7, 'Commission must be at least 7%'),
    cookieLifespan: number(),
    locationUrl: string(),
    externalId: string(),
    image: custom<File>().optional()
});

// 👇 Infer the Schema to get TypeScript Type
export interface IProduct extends TypeOf<typeof productSchema> {
    id?: number
    businessId?: number
    thumbUrl?: string
}

type Field = "name" | "categoryId" | "price" | "description" | "commission" | "cookieLifespan" | "businessId" | "id" | "image"

const ProductForm = ({ item, businessId, callback }: {
    item?: IProduct
    businessId?: number
    callback?: (i?: IProduct) => void
}) => {
    const [loading, setLoading] = useState(false)
    const thumbUrl = item?.thumbUrl
    const [picture, setPicture] = useState<string | null>(thumbUrl || null);
    const { categories, loading: loadingCategories } = useCategoryList('product')
    const defaultValues: IProduct = item || {
        name: '',
        categoryId: 0,
        price: 0,
        description: '',
        note: '',
        commission: 7,
        cookieLifespan: 1,
        locationUrl: '',
        externalId: '',
    };

    // 👇 Object containing all the methods returned by useForm
    const methods = useForm<IProduct>({
        resolver: zodResolver(productSchema),
        defaultValues,
    });

    // 👇 Form Handler
    const onSubmitHandler: SubmitHandler<IProduct> = (values: IProduct) => {
        setLoading(true)
        if (!values.businessId) {
            values.businessId = businessId
        }
        console.log(values)
        const formData = new FormData();
        const data = Object.assign(values)
        for (const key in data) {
            formData.append(key, data[key]);
        }
        const action = item?.id ? productService.updateProduct : productService.createProduct
        action(formData, item?.id || '')
            .then(({ data }) => {
                if (!item?.id) {
                    methods.reset()
                }
                Swal.fire({
                    text: 'Product saved',
                    icon: 'success',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                })
                callback?.(item?.id ? { ...item, ...values } : data)
            })
            .catch((resError) => {
                Swal.fire({
                    text: resError?.error || 'Failed to save.',
                    icon: 'warning',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Try again',
                    allowOutsideClick: false,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const updateNumeric = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        methods.setValue(evt.target.name as Field, parseInt(evt.target.value))
    }

    const onFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const fileListObj: FileList | null = evt.target.files;
        if (fileListObj) {
            console.log(fileListObj)
            methods.setValue(evt.target.name as Field, fileListObj[0])
            setPicture(URL.createObjectURL(fileListObj[0]));
        }
    }

    return (
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
                <FormInput
                    label='Product Name'
                    type='text'
                    name='name'
                    focused
                    required
                />
                <FormInput
                    label='Product Unique Id'
                    helperText='A unique id used for the product in your website'
                    type='text'
                    name='externalId'
                    focused
                    required
                />
                <FormInput
                    label='Product Link Url'
                    type='text'
                    name='locationUrl'
                    focused
                    required
                />
                <Box sx={{ mb: 3 }} display="flex" flexDirection={'column'} justifyContent="center" alignItems={'center'}>
                    <input
                        type='file'
                        id='image'
                        name="image"
                        placeholder="Upload Product Image"
                        required
                        onChange={onFileChange}
                    />

                    {picture ? <img className="image" width={300} height={320} src={picture} alt="" /> : null}
                </Box>

                {loadingCategories ? <div>Fetching categories...</div> : <FormInput
                    select
                    label='Category'
                    name='categoryId'
                    onChange={updateNumeric}
                    variant="standard"
                    required
                >
                    {categories.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </FormInput>}
                <FormInput
                    label='Description'
                    type='text'
                    name='description'
                    focused
                    rows={3}
                    multiline
                    required
                />
                <FormInput
                    label='Price(Ksh)'
                    type='number'
                    name='price'
                    onChange={updateNumeric}
                    placeholder='200'
                    focused
                />
                <FormInput
                    label='Commission'
                    type='number'
                    onChange={updateNumeric}
                    name='commission'
                    focused
                    required
                />
                <FormInput
                    label='Commission application duration(days)'
                    type='number'
                    onChange={updateNumeric}
                    name='cookieLifespan'
                    placeholder='3'
                    focused
                />

                <FormInput
                    label='Note to Marketter'
                    type='text'
                    name='note'
                    focused
                    rows={3}
                    multiline
                />

                <LoadingButton
                    loading={loading}
                    type='submit'
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                        width: '80%',
                        marginInline: 'auto',
                    }}
                >
                    Save
                </LoadingButton>
            </Box>
        </FormProvider>
    );
};

export default ProductForm;
