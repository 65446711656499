import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IProductData from 'types/product.type';
import truncate from 'helpers/truncate';
import { Badge, BadgeProps, Box, Button, Chip, Divider, Grid, Link, Tooltip, useMediaQuery } from '@mui/material';
import formatCurrency from 'helpers/formatCurrency';
import CampaignDataService from 'services/campaign.service';
import useAuth from 'hooks/useAuth';
import Swal from 'sweetalert2';
import ExpandMore from 'components/ExpandMore';


const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 11,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export default function ProductCard({ product }: { product: IProductData }) {
    const [expanded, setExpanded] = useState(false);
    const { auth } = useAuth()
    const theme = useTheme();
    const role = (auth && auth.currentRole) || ''
    const myCampaign = (product?.campaigns || []).find(c => c.userId === auth?.id)
    const [activeCampaign, setActiveCampaign] = useState(myCampaign)
    const campaignUrl = `${product?.locationUrl}?referal=adfiti&adfiti_token=${activeCampaign?.uniqueId}`
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    useEffect(() => {
        if (myCampaign?.uniqueId) {
            setActiveCampaign(myCampaign)
        }
    }, [myCampaign])

    const copyMarkettingUrl = () => {
        navigator.clipboard.writeText(campaignUrl);
        Swal.fire({
            title: 'Copied successfully!',
            text: `Share the copied link to your marketting platforms to start earning.`,
            icon: 'info',
        })
    }
    const addToCampaign = () => {
        Swal.fire({
            title: 'Confirm',
            text: `You are about to pick this product to start marketting it`,
            icon: 'info',
            confirmButtonText: 'Proceed',
            allowOutsideClick: false,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                CampaignDataService.create({ productId: product.id }).then(({ data }) => {
                    if (data) {
                        setActiveCampaign(data)
                    }
                })
            }
        })

    }

    return (
        <Card raised sx={{ height: '100%' }}>
            <Grid container>
                <Grid item xs={4} sm={12}>
                    <CardMedia
                        component="img"
                        height={isMobile ? "100%" : "194"}
                        image={product.thumbUrl}
                        alt={product?.name}
                    />
                </Grid>
                <Grid item xs={8} sm={12}>
                    <CardContent>
                        <Typography gutterBottom variant="body2" component="div">
                            {truncate(product.name, 45)}
                        </Typography>
                        <Typography display="block" variant="caption" color="text.secondary">
                            {activeCampaign ? <Chip label="promoting" color='primary' size='small' /> : null}
                        </Typography>
                        <Typography variant='subtitle1'>{formatCurrency(product?.price)}</Typography>
                        <Tooltip title={<>
                            <Typography paragraph sx={{ p: 1 }}>
                                Your commission is {`${product?.commission}%`} of the product price whenever a
                                successful sale is made for this product.
                            </Typography>
                            <Typography paragraph sx={{ p: 1 }}>
                                Validity represents the period within which buyers must make a purchase of the product after accessing the product you are promoting for you to get your commission.
                                If the buyer uses your promotional link to access this product and buys the product within {`${product?.cookieLifespan} days`} you will get {`${product?.commission}%`} commission on the value of the products bought by the buyer.
                            </Typography>
                        </>}>
                            <StyledBadge
                                badgeContent={'?'}
                                color="primary"
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}>
                                <Box pr={1}>
                                    <Typography variant="caption" color="text.secondary">
                                        {`${product?.commission}% commision`} • {`${product?.cookieLifespan} days validity`}
                                    </Typography>
                                </Box>

                            </StyledBadge>
                        </Tooltip>

                    </CardContent>
                </Grid>
            </Grid>


            <Divider />
            <CardActions disableSpacing>
                {['affiliate'].includes(role) && !activeCampaign ? <Button variant='outlined' size="small" onClick={addToCampaign}>Promote</Button> : null}
                {activeCampaign ?
                    <Button size='small' color={'success'} variant='text' onClick={copyMarkettingUrl} aria-label="request affiliate marketting url">
                        Share
                    </Button> : null}

                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph variant='subtitle1'>Name: <Link href={product?.locationUrl} target="_blank">{product.name}</Link></Typography>
                    <Typography paragraph variant='subtitle1'>Retailer: {product?.business?.name}</Typography>
                    <Typography paragraph variant='h6'>Description</Typography>
                    <Typography paragraph sx={{ whiteSpace: 'pre-line' }}>
                        {product.description}
                    </Typography>
                    <Typography paragraph variant='h6'>Note to marketter</Typography>
                    <Typography paragraph>
                        {product.note}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}