import { Card, CardContent, CardHeader } from '@mui/material';
import BusinessForm from 'modules/business/business.form';
import React from 'react';
import MainLayout from '../layouts/main.layout';
import { useNavigate } from 'react-router';

export default function AddBusiness() {
  const navigate = useNavigate()
  return (
    <MainLayout>
      <Card>
        <CardHeader title="Create a New Business" subheader="Fill in your business information below" />
        <CardContent>
          <BusinessForm onSubmit={() => navigate('/business')} onCancel={() => navigate('/business')} />
        </CardContent>
      </Card>

    </MainLayout>
  );
}