import BaseDataService from "./base.service";

export class InvoiceDataService extends BaseDataService {
    constructor() {
        super({ name: 'invoices' })
    }
}

const service = new InvoiceDataService();

export default service