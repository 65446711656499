import http from "services/api.request";
import getQueryString from "helpers/getQueryString";
import { TPaginatedData } from "types/common.type";
import IBusinessData from "types/business.type";
import ISaleData from "types/sale.type";
import IProductData from "types/product.type";
import ICampaignData from "types/campaign.type";
import IInvoiceData from "types/invoice.type";
import { User } from "types/user.type";
import IPaymentData from "types/payment.type";
import { PaymentMethod } from "types/payment.method.type";

type TResponseData = IBusinessData | ISaleData | IProductData | ICampaignData | IInvoiceData | User | IPaymentData | PaymentMethod

type TName = 'business' | 'sales' | 'products' | 'campaigns' | 'invoices' | 'users' | 'payments' | 'payment-methods'

class BaseDataService {
    name: TName
    constructor(props: any) {
        this.name = props?.name
    }

    find = (params?: Record<string, number | string | undefined>) => {
        const queryString = params ? getQueryString(params) : ''
        return http.get<TPaginatedData>(`/${this.name}/${queryString}`);
    }

    get = (id: string | number) => {
        return http.get<TResponseData>(`/${this.name}/${id}`);
    }

    create = (data: TResponseData) => {
        return http.post<any>(`/${this.name}`, data);
    }

    update = (data: TResponseData, id: string | number) => {
        return http.put<any>(`/${this.name}/${id}`, data);
    }

    delete = (id: string | number) => {
        return http.delete<any>(`/${this.name}/${id}`);
    }

    deleteAll = () => {
        return http.delete<any>(`/${this.name}`);
    }
}

export default BaseDataService;