import React, { useMemo } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'
import Swal from 'sweetalert2'
import useAuth from 'hooks/useAuth'

type TUnknownCallback = (...args: any) => void

export type TMenuItem = {
  label: string;
  name?:string;
  confirm?: boolean;
  roles?: string[];
  hide?: (...params: any) => void;
  run: (...params: any) => void
}

interface IContextMenu {
  items: Array<TMenuItem>
  value?: any
  callback?: TUnknownCallback
}

export function ContextMenu({ items, value, callback }: IContextMenu) {
  const {auth} = useAuth()
  const role = auth && auth.currentRole ? auth.currentRole : ''
  const menuItems = useMemo(
    () => items.filter((i) => {
      if (i?.hide?.(value)) {
        return false
      }
      if (i.roles && !i.roles?.includes(role)) {
        return false
      }
      return true
      
    }),
    [value, items, role]
  )
  const [anchorEl, setAnchorEl] = React.useState<any>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (item: TMenuItem) => {
    if (item.confirm) {
      Swal.fire({
        title: 'Confirm',
        text: `Are you sure you want to ${item.label}`,
        icon: 'warning',
        confirmButtonText: 'Proceed',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          item.run(value, callback)
        }
      })
      setAnchorEl(null)
      return
    }
    if (item && item.run) item.run(value, callback)
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick} edge="end" aria-label="More Actions">
        <MoreVertIcon />
      </IconButton>
      {menuItems.length > 0 && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {(menuItems || []).map((item) => (
            <MenuItem key={item.label} onClick={() => handleClose(item)}>
              {item?.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  )
}

export default ContextMenu
