import { useEffect, useState } from 'react';

import categoryService from 'services/category.service';
import ICategoryData from 'types/category.type';

export default function useCategoryList(type: string) {

  const [categories, setCategoryList] = useState<ICategoryData[]>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setLoading(true)
    categoryService.findByType(type).then(({ data }) => {
      setCategoryList(data)
    })
      .catch((error) => {
        console.error(error)
        setError(error.message)
      })
      .finally(() => setLoading(false))
  }, [type])

  return { categories, loading, error }
}