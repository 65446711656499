import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UserForm, { IUser } from 'modules/profile/user.form'
import Loader from 'components/loader'
import ChangePassword from 'modules/profile/ChangePassword'
import storage from 'helpers/secure-storage'
import { keys } from 'helpers/constants'

const AccountProfile = () => {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true)
    const res = storage.get(keys.USER)
    setUser(res)
    setLoading(false)
  }, [])

  const updateLocalUser = (updates: IUser) => {
    const currentUser = storage.get(keys.USER)
    if (currentUser) {
      storage.set(keys.USER, { ...currentUser, ...updates })
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Box display={'flex'} px={{ xs: 0, sm: '20%' }} flexDirection='column'>
      <Card>
        <CardContent>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="my profile tabs">
                <Tab label="General" value="1" />
                <Tab label="Account" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">{user ? <UserForm user={user} callback={updateLocalUser} /> : null}</TabPanel>
            <TabPanel value="2"><ChangePassword /></TabPanel>
          </TabContext>
        </CardContent>
      </Card>


    </Box>

  )
}

export default AccountProfile
