import { Alert, AlertTitle, Container, Link } from '@mui/material';

export default function PageNotFound() {
  return (
    <Container>
      <Alert severity="error">
      <AlertTitle>Page Not Found</AlertTitle>
      You tried to access an invalid url — <Link href="/" variant="h6">
        Get back home
      </Link>
    </Alert>
    </Container>
    
  );
}