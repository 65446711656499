import { useEffect, useReducer, useState } from 'react';

import { BusinessDataService } from 'services/business.service';
import { ProductDataService } from 'services/product.service';
import { SaleDataService } from 'services/sale.service';

type TService = BusinessDataService | ProductDataService | SaleDataService

type TPaginatedResponse = {
  rows: Array<any>,
  count: number,
  currentPage: number,
  totalPages: number
}

export default function usePaginatedSearch(service: TService, params?: Record<string, number | string | undefined>) {

  const [data, setData] = useState<TPaginatedResponse>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [refetchCount, dispatchRefetch] = useReducer((state) => state + 1, 0)

  useEffect(() => {
    if (refetchCount >= 0) {
      console.log('fetching...')
      setLoading(true)
      service.find(params)
        .then(({ data }) => {
          setData(data)
        })
        .catch((error) => {
          console.error(error)
          setError(error.message)
        })
        .finally(() => setLoading(false))
    }
  }, [params, refetchCount])

  return {
    data,
    error,
    loading,
    refetch: dispatchRefetch
  }
}