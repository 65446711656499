import { Box, MenuItem, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FC, useContext, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from 'components/FormInput';
import Swal from 'sweetalert2';
import getUserName from 'helpers/getUserName';
import paymentMethodService from 'services/payment.method.service';
import { AuthContext } from 'App';

// 👇 Schema with Zod
const paymentMethodSchema = object({
    accountHolderName: string().min(1, 'Account holder name is required').max(70),
    accountHolderIDType: string().min(1, 'Select identification type'),
    address: string().min(1, 'Address is required').max(100),
    accountHolderIDNo: string().min(5, 'ID number is required'),
    account_number: string(),
    name: string(),
    type: string(),
});

// 👇 Infer the Schema to get TypeScript Type
export interface IPaymentMethod extends TypeOf<typeof paymentMethodSchema> {
    id?: number | string
    ac_no_last_digits?: string
}

const accountHolderIDTypes = [{
    label: 'National ID',
    value: 'national_id'
}, {
    label: 'Passport',
    value: 'passport'
}, {
    label: 'Driver License',
    value: 'driver_license'
}]
const PaymentMethodForm: FC = ({ item }: {
    item?: IPaymentMethod
}) => {
    const [loading, setLoading] = useState(false)
    const { auth: user } = useContext(AuthContext)
    const userFullName = user ? getUserName(user) : ''
    // 👇 Default Values
    const defaultValues: IPaymentMethod = item || {
        name: 'mpesa',
        type: 'mobile',
        accountHolderName: userFullName || '',
        accountHolderIDType: '',
        accountHolderIDNo: '',
        account_number: user?.phone,
        address: '',
    };

    // 👇 Object containing all the methods returned by useForm
    const methods = useForm<IPaymentMethod>({
        resolver: zodResolver(paymentMethodSchema),
        defaultValues,
    });

    // 👇 Form Handler
    const onSubmitHandler: SubmitHandler<IPaymentMethod> = (values: IPaymentMethod) => {
        setLoading(true)
        const action = item?.id ? paymentMethodService.update : paymentMethodService.create
        action(values, item?.id || '')
            .then(() => {
                methods.reset()
                Swal.fire({
                    text: 'Payment method saved',
                    icon: 'success',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                })
            })
            .catch((resError) => {
                Swal.fire({
                    text: resError?.error || 'Failed to save.',
                    icon: 'warning',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Try again',
                    allowOutsideClick: false,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
                <FormInput
                    label='Account Holder Full Name'
                    type='text'
                    name='accountHolderName'
                    focused
                    required
                />
                <FormInput
                    select
                    label='Account Holder ID Type'
                    name='accountHolderIDType'
                    type='text'
                    variant="standard"
                >
                    {accountHolderIDTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </FormInput>
                <FormInput
                    label='ID Number'
                    type='text'
                    name='accountHolderIDNo'
                    focused
                    required
                />
                {item?.id ? <Box sx={{mb: 3}}>
                    <Typography variant='subtitle2'>Mobile Number</Typography>
                    <Typography variant='body2'>{`${(item?.name || '').toUpperCase()} ending in ${item?.ac_no_last_digits}`}</Typography>
                </Box> : <FormInput
                    label='Mobile Number'
                    type='text'
                    name='account_number'
                    focused
                    required
                />}
                <FormInput
                    label='Address'
                    type='text'
                    multiline
                    rows={4}
                    name='address'
                    focused
                    required
                />

                <LoadingButton
                    loading={loading}
                    type='submit'
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                        width: '80%',
                        marginInline: 'auto',
                    }}
                >
                    Save
                </LoadingButton>
            </Box>
        </FormProvider>
    );
};

export default PaymentMethodForm;
