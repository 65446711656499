import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import formatCurrency from 'helpers/formatCurrency';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useMemo, useState } from 'react'
import ApiRequest from 'services/api.request'
import IProductData from 'types/product.type';
import ISaleData from 'types/sale.type';

type TResponse = {
    id: number
    product: IProductData & { sales: ISaleData[] }
}

const AllocationCells = ({ sales }: {
    sales: ISaleData[]
}) => {
    const { auth } = useAuth();
    const role = auth && auth.currentRole ? auth.currentRole : ''
    const totalDeductions = useMemo(() => {
        return sales.reduce((acc, cur) => acc + (cur?.affiliateShare || 0) + (cur.fees || 0), 0)
    }, [sales])

    const feesTotal = useMemo(() => {
        return sales.reduce((acc, cur) => acc + (cur.fees || 0), 0)
    }, [sales])

    const affiliateRevenue = useMemo(() => {
        return sales.reduce((acc, cur) => acc + (cur?.affiliateShare || 0), 0)
    }, [sales])

    switch (role) {
        case 'admin':
            return (
                <>
                    <TableCell>{formatCurrency(affiliateRevenue)}</TableCell>
                    <TableCell>{formatCurrency(feesTotal)}</TableCell>
                </>
            )
        case 'business':
            return (
                <>
                    <TableCell>{formatCurrency(totalDeductions)}</TableCell>
                </>
            )
        case 'affiliate':
            return (
                <>
                    <TableCell>{formatCurrency(affiliateRevenue)}</TableCell>
                </>
            )

        default:
            return <TableCell />
    }
}

const AllocationHeaderCells = () => {
    const { auth } = useAuth();
    const role = auth && auth.currentRole ? auth.currentRole : ''

    switch (role) {
        case 'admin':
            return (
                <>
                    <TableCell>Affiliate Share</TableCell>
                    <TableCell>Service Fee</TableCell>
                </>
            )
        case 'business':
            return (<TableCell>Service Fee</TableCell>)
        case 'affiliate':
            return (<TableCell>Income</TableCell>)

        default:
            return <TableCell />
    }
}
type TSaleStage = 'in-progress' | 'in-review'

export default function SalesInprogress({ updateValue, stage }: { stage: TSaleStage, updateValue?: (value: number) => void }) {
    const [data, setData] = useState<TResponse[]>([])
    const { auth } = useAuth();
    const role = auth && auth.currentRole ? auth.currentRole : ''

    useEffect(() => {
        let route = null
        if (stage === 'in-progress') {
            route = 'sales-inprogress'
        } else if (stage === 'in-review') {
            route = 'sales-inreview'
        }
        if (route) {
            ApiRequest.get(`reports/${route}`)
                .then(({ data }) => {
                    setData(data.rows)
                })
                .catch(e => console.error(e))
        }

    }, [stage])

    useEffect(() => {
        const sales = (data || []).reduce((acc: any[], cur: TResponse) => [...acc, ...cur.product.sales], [])
        if (role === 'affiliate') {
            const totalAffiliateShare = sales.reduce((acc: number, cur: ISaleData) => {
                if (cur.affiliateShare) {
                    return acc + cur.affiliateShare
                }
                return acc
            }, 0)
            updateValue?.(totalAffiliateShare)
        } else if (role === 'business') {
            const totalSalesValue = sales.reduce((acc: number, cur: ISaleData) => {
                if (cur.totalValue) {
                    return acc + cur.totalValue
                }
                return acc
            }, 0)
            updateValue?.(totalSalesValue)
        }
    }, [data, role, updateValue])

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell>Product</TableCell>
                    <TableCell>No. of Orders</TableCell>
                    <TableCell>Order Value</TableCell>
                    <AllocationHeaderCells />
                </TableHead>
                <TableBody>
                    {data.map((item: TResponse) => (
                        <Row key={item.id} item={item} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function Row({ item }: { item: TResponse }) {
    const { product } = item
    const total = useMemo(() => {
        return product.sales.reduce((acc, cur) => acc + cur.totalValue, 0)
    }, [product])

    return (
        <TableRow>
            <TableCell>{product?.name}</TableCell>
            <TableCell>{product?.sales.length}</TableCell>
            <TableCell>{formatCurrency(total)}</TableCell>
            <AllocationCells sales={product.sales} />
        </TableRow>
    )
}