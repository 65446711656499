import { Box, Typography } from '@mui/material';
import BalanceCard from 'modules/settings/balance.card';
import PaymentDetails from 'modules/settings/payment.details';
import React from 'react';
import MainLayout from '../layouts/main.layout';

export default function PaymentMethods() {
  return (
    <MainLayout>
      <Typography variant='h6' padding={2}>Get paid</Typography>
      <BalanceCard />
      <Box my={2}>
        <PaymentDetails />
      </Box>
    </MainLayout>
  );
}