import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { Toolbar, IconButton, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import useAuth from 'hooks/useAuth';
import TopMenuItems from 'components/topbar.menu.items';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface ITopNavigation {
  open?: boolean;
  toggleDrawer?: () => void
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export default function TopNavigation({ toggleDrawer, open }: ITopNavigation) {
  const { auth } = useAuth()
  const role = (auth && auth.currentRole) || ''
  const portalName = role.charAt(0).toUpperCase() + (role || '').substring(1)

  return (
    <AppBar position="fixed" /* sx={{ background: '#272e3d' }} */ open={open}>
      <Box maxWidth="100%">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              sx={{
                marginRight: 5,
                display: { xs: 'block', md: 'none' }
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Adfiti {portalName} Portal
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <TopMenuItems open />
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  )
}
