import {
  Container,
  Grid,
} from '@mui/material';
import { FC } from 'react';
import LoginForm from 'modules/auth/login.form';

const LoginPage: FC = () => {

  // 👇 JSX to be rendered
  return (
    <Container
      maxWidth={false}
      sx={{ height: '100vh', backgroundColor: { xs: '#fff', md: '#f4f4f4' } }}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ width: '100%', height: '100%' }}
      >
        <Grid
          item
          sx={{ maxWidth: '70rem', width: '100%', backgroundColor: '#fff' }}
        >
          <LoginForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;
