import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { Container } from '@mui/material';

import TopNavigation from 'components/TopNavigation';
import DashboardSidebar from 'components/sidebar';

export const drawerWidth = 240;
interface IMainLayout {
    children: any
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
    }),
);

export default function MainLayout({ children }: IMainLayout) {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen((state) => !state);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', fontSize: '0.875rem' }}>
            <CssBaseline />
            <TopNavigation open={open} toggleDrawer={toggleDrawer} />

            <Drawer
                variant="temporary"
                sx={{ display: { xs: 'block', md: 'none' } }}
                open={open}
                onClose={handleDrawerClose}
            >
                <DrawerHeader />
                <Divider />
                <DashboardSidebar onClose={handleDrawerClose} open={open} />
            </Drawer>
            <Container maxWidth={'xl'} component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
            </Container>
        </Box>
    );
}