import http from "./api.request";
import IProductData from "types/product.type"
import BaseDataService from "services/base.service";

export class ProductDataService extends BaseDataService {
    constructor() {
        super({ name: 'products' })
    }

    findByName(name: string) {
        return http.get<Array<IProductData>>(`/products?name=${name}`);
    }
    createProduct = (data: FormData) => {
        return http.post<IProductData>(`/${this.name}`, data);
    }

    updateProduct = (data: FormData, id: string | number) => {
        return http.put<any>(`/${this.name}/${id}`, data);
    }
}

const service = new ProductDataService();

export default service