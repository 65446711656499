import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, AlertTitle, Box, Chip, Typography } from '@mui/material';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import Loader from 'components/loader'
import paymentService from 'services/payment.service';
import formatCurrency from 'helpers/formatCurrency';
import { AuthContext } from 'App';
dayjs.extend(localizedFormat)

export default function PaymentList() {
  const { auth: { id: userId } } = useContext(AuthContext)
  const [query, setQuery] = useState<Record<string, number | string | undefined>>({
    ...(userId && { userId })
  })
  useEffect(() => {
    setQuery((state) => {
      return {
        ...state,
        userId
      }
    })
  }, [userId])

  const { data, loading, error } = usePaginatedSearch(paymentService, query)

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error getting data</AlertTitle>
        {error}
      </Alert>
    )
  }
  if (loading) {
    return <Loader />
  }

  if (!data) {
    return (
      <Typography variant='subtitle1'>Data not available yet</Typography>
    )
  }

  const { rows, count } = data
  if (count === 0) {
    return <Box>
      <Typography>No Payment History Found</Typography>
    </Box>
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="business table">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.description}
              </TableCell>
              <TableCell align="right">{formatCurrency(row?.amount)}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right"><Chip label={row.status} color={row.status === 'complete' ? "success": "default"} variant="filled" /></TableCell>
              <TableCell align="right">{dayjs(row?.createdAt).format('LL hh:mm a')}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}