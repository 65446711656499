import React, { useContext, useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, MenuItem, Typography, Divider, OutlinedInput, InputAdornment, Collapse, Box, useMediaQuery, Button, List, ListItem, ListItemText, Alert } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ProductList from 'modules/product/product.list';
import { useTheme } from '@mui/material/styles'

import MainLayout from '../layouts/main.layout';
import useCategoryList from 'hooks/useCategoryList';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import businessService from 'services/business.service';
import Loader from 'components/loader';
import productService from 'services/product.service';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModalComponent from 'components/Modal';
import NewProduct from 'modules/product/add.product';
import { AuthContext } from 'App';
import MainCard from 'components/MainCard';
import ICategoryData from 'types/category.type';
import ExpandMore from 'components/ExpandMore';

type TProductFilters = {
  businessId?: string;
  categoryId?: number;
  name?: string;
  size?: number;
}
export default function Products() {
  const { data: businesses } = usePaginatedSearch(businessService)
  const theme = useTheme();
  const [query, setQuery] = useState<TProductFilters>({ size: 32 })
  const { data: products, loading: loadingProducts, refetch } = usePaginatedSearch(productService, query || {})
  const [searchText, setSearchText] = useState<string>('')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState<boolean>(isMobile ? false : true)
  const [title, setTitle] = useState<string | null>(null)


  const { auth } = useContext(AuthContext)
  const role = auth ? auth.currentRole : ''
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  })
  const debouncedSearchTerm = useDebounce(searchText, 500)
  const handleChange = (event: SelectChangeEvent) => {
    const name = event.target.name
    setQuery(state => ({ ...state, [name]: event.target.value }))
  };
  useEffect(() => setQuery(state => ({ ...state, 'name': debouncedSearchTerm })), [debouncedSearchTerm])
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setQuery(state => ({ ...state, page: page - 1 }))
  }

  const handleAddClick = () => {
    ModalComponent.show({
      component: NewProduct,
      title: 'Create a New Product',
      noDelay: true,
      passProps: {
        callback: () => {
          ModalComponent.hide()
          refetch()
        },
      },
    })
  }

  const handleCategorySelect = (category: ICategoryData | null) => {
    setQuery(state => {
      const { categoryId, ...updates } = state
      return ({ ...updates, ...(category?.id && { categoryId: category?.id }) })
    })
    setTitle(category?.name || '')
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MainLayout>
      {/* <Typography variant='h6' paragraph>Products</Typography> */}
      <Box display={'flex'} my={2} justifyContent='space-between'>
        <FormControl fullWidth variant="outlined" size='small' sx={{ float: 'left', mb: { xs: 1 } }}>
          <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            type='text'
            name="name"
            onChange={(event) => setSearchText(event.target.value)}
            endAdornment={
              <InputAdornment position="end"> <SearchIcon />
              </InputAdornment>
            }
            label="Search"
          />
        </FormControl>
        {['business'].includes(role) ?
          <Button
            variant='contained'
            sx={{ float: 'right', ml: 2, mb: 1, minWidth: 'fit-content' }}
            onClick={handleAddClick}><AddIcon /> {lgUp ? 'Add Product' : ''}</Button> : null}
      </Box>

      <Grid container spacing={{ sm: 2 }}>
        <Grid item xs={12} sm={2}>
          <MainCard title="Filter Products" contentSX={{ px: 0 }}
            secondary={<ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              color='inherit'
              sx={{ display: { sm: 'none' } }}
            >
              <ExpandMoreIcon />
            </ExpandMore>}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {['business'].includes(role) ? <FormControl size='small' variant='outlined' fullWidth sx={{ mb: { xs: 1 } }}>
                <InputLabel id="simple-select-business">Select Business</InputLabel>
                {!businesses ? <Loader /> : <Select
                  value={query?.businessId}
                  label="Select Business"
                  id="simple-select-business"
                  name="businessId"
                  onChange={handleChange}
                  defaultValue=""
                >
                  <MenuItem value={''}>---select business---</MenuItem>
                  {(businesses.rows || []).map((row) => (
                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                  ))}

                </Select>}
              </FormControl> : null}
              <CategoriesFilter handleClick={handleCategorySelect} />
            </Collapse>

          </MainCard>

        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
        >
          {title ? <Typography variant='h6' mb={2}>{title}</Typography> : null}
          {products?.count === 0
            ? <Alert severity="info">No products found for your current search!</Alert>
            : <ProductList
              products={products?.rows || []}
              loading={loadingProducts}
              pagination={{
                onPageChange: handlePageChange,
                count: products?.totalPages || 0,
                page: (products?.currentPage) || 0 + 1,
                totalRecords: (products?.count) || 0
              }} refetch={refetch} />}

        </Grid>
      </Grid >

    </MainLayout >
  );
}

function CategoriesFilter(
  { handleClick }: { handleClick: (category: ICategoryData | null) => void }
) {
  const { categories, loading } = useCategoryList('product')

  if (loading) {
    return <Loader />
  }
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: { sm: 360 },
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: { xs: 200, sm: 500 },
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
      <ListItem button onClick={() => handleClick(null)}>
        <ListItemText primary="All" />
      </ListItem>
      <Divider />
      {(categories || []).map((row) => (
        <ListItem button key={row.id} onClick={() => handleClick(row)}>
          <ListItemText primary={row.name} />
        </ListItem>
      ))}
    </List>
  );
}