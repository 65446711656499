import React from 'react'

import { Box, Pagination, PaginationItem } from '@mui/material'

const withPagination = (Component: any) => {
  const WithPagination = ({
    pagination: { count, page, totalRecords, onPageChange },
    ...props
  }: {
    [x: string]: any;
    pagination: {
      count: number;
      page: number;
      totalRecords: number;
      onPageChange: (e: React.ChangeEvent<unknown>, val: number) => void;
    };
  }) => {
    return (
      <>
        <Component {...props} />
        <Box mt={3} display="flex" justifyContent="center">
          {count > 1 && (
            <Pagination
              count={count}
              boundaryCount={3}
              color="primary"
              variant="outlined"
              page={page}
              onChange={onPageChange}
              renderItem={(item) => <PaginationItem {...item} />}
            />
          )}
        </Box>
      </>
    )
  }

  WithPagination.displayName = `WithPagination(${Component.displayName || Component.name || 'Component'
    })`
  WithPagination.defaultProps = {
    pagination: { page: 1, limit: 10 },
  }
  return WithPagination
}

export default withPagination
