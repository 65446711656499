import React from 'react';
import { Avatar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import withPagination from 'components/withPagination';
import ISaleData from 'types/sale.type';
import getUserName from 'helpers/getUserName';
import useAuth from 'hooks/useAuth';
import formatCurrency from 'helpers/formatCurrency';
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

export default withPagination(SaleList)

export function SaleList({ sales, refetch }: { sales: Array<ISaleData>, refetch?: () => void }) {
    const { auth } = useAuth()
    const role = (auth && auth.currentRole) || ''

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="business table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">Product</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        {['admin'].includes(role) ? <TableCell align="right">User</TableCell> : null}
                        <TableCell align="right">Affiliate Share</TableCell>
                        <TableCell align="right">Service Fee</TableCell>
                        <TableCell align="right">Entry Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(sales || []).map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='left'>
                                <Avatar
                                    sx={{ width: 56, height: 56 }}
                                    src={row?.product?.thumbUrl}
                                    alt={row?.product?.name}
                                    variant="rounded"
                                />
                            </TableCell>
                            <TableCell component="th" scope="row" align='left'>
                                {row?.product?.name}
                            </TableCell>
                            <TableCell align="right">{row?.quantity}</TableCell>
                            {['admin'].includes(role) ? <TableCell align="right">{row?.affiliate ? getUserName(row.affiliate) : ''}</TableCell> : null}
                            <TableCell align="right">{formatCurrency(row.affiliateShare)}</TableCell>
                            <TableCell align="right">{formatCurrency(row.fees)}</TableCell>
                            <TableCell align="right">
                                <Tooltip title={dayjs(row?.createdAt).format('LL hh:mm a')} placement="left">
                                    <Typography variant='body2'>{dayjs(row?.createdAt).fromNow()}</Typography>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

