import http from "./api.request";
import BaseDataService from "services/base.service";
import IPaymentData from "types/payment.type";

export class PaymentDataService extends BaseDataService {
    constructor() {
        super({ name: 'payments' })
    }

    findByType(type: string) {
        return http.get<Array<IPaymentData>>(`/payments?type=${type}`);
    }
}

const service = new PaymentDataService();

export default service