export const keys = {
  USER: 'user',
}

export const publicUrls = ['/signup', '/login']

export const USER_ROLES = [
  { name: "business", label: "Business Owner" },
  { name: "affiliate", label: "Affiliate Marketter" },
]

export const paymentProcessors = {
  bank: [
    'ABC Bank (Kenya)',
    'Absa Bank Kenya',
    'Access Bank Kenya',
    'Bank of Africa',
    'Bank of Baroda',
    'Bank of India',
    'Citibank',
    'Consolidated Bank of Kenya',
    'Cooperative Bank of Kenya',
    'Credit Bank',
    'Development Bank of Kenya',
    'Diamond Trust Bank',
    'Dubai Islamic Bank',
    'Ecobank Kenya',
    'Equity Bank Kenya',
    'Family Bank',
    'First Community Bank',
    'Guaranty Trust Bank Kenya',
    'Guardian Bank',
    'Gulf African Bank',
    'Habib Bank AG Zurich',
    'Housing Finance Company of Kenya',
    'I&M Bank',
    'Imperial Bank Kenya',
    'Kingdom Bank Limited',
    'Kenya Commercial Bank',
    'Mayfair Bank',
    'Middle East Bank Kenya',
    'M Oriental Bank',
    'National Bank of Kenya',
    'NCBA Bank Kenya',
    'Paramount Universal Bank',
    'Prime Bank (Kenya)',
    'SBM Bank Kenya',
    'Sidian Bank',
    'Spire Bank',
    'Stanbic Holdings Plc',
    'Standard Chartered Kenya',
    'United Bank for Africa',
    'Victoria Commercial Bank',
  ],
  mobile_money: ['M-pesa', 'T-cash', 'Airtel-money'],
}
