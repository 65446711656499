import { useCallback, useEffect, useState } from 'react';
import storage from 'helpers/secure-storage'
import { keys } from 'helpers/constants'
import { User } from 'types/user.type';

const getUser = async () => {
  const user = await storage.get(keys.USER);
  return user
};

export default function useAuth() {

  const [user, setUser] = useState<User>();
  const token = user?.accessToken

  useEffect(() => {
    getUser().then(res => {
      if (res !== token) {
        setUser(res)
      }
    })
  }, [token])

  const setAuth = useCallback((user: any) => {
    storage.set(keys.USER, user);
    setUser(user);
  }, [])

  return {
    setAuth,
    token,
    auth: user
  }
}