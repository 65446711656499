import React from 'react';
import { Avatar, Box, Collapse, Hidden, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Typography, useTheme, Skeleton } from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import TimelineIcon from '@mui/icons-material/TimelineOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { green } from '@mui/material/colors';
import productService from 'services/product.service';
import Swal from 'sweetalert2';
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import ProductForm, { IProduct } from 'modules/product/product.form';
import ModalComponent from 'components/Modal';
import ContextMenu from 'components/menu.component';
import IProductData from 'types/product.type';
import withPagination from 'components/withPagination';
import ProductCard from './product.card';
import useAuth from 'hooks/useAuth';
dayjs.extend(localizedFormat)

export default withPagination(ProductList)

export function ProductList({ products, refetch, loading = true }: { products: Array<IProductData>, refetch?: () => void, loading?: boolean }) {

  const { auth } = useAuth();
  const role = auth && auth.currentRole ? auth.currentRole : ''
  const classes = {
    root: {
      width: '100%',
    },
  }

  return (
    <>
      {['affiliate'].includes(role) ?
        <Grid container spacing={2} >
          {(loading ? Array.from(new Array(9)) : products).map((product, index) => (
            <Grid item xs={12} sm={3} key={product ? product.id : index}>
              {product ? <ProductCard product={product} /> : <>
                <Skeleton variant="rounded" height={118} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </>}
            </Grid>
          ))}
        </Grid> : ['business', 'admin'].includes(role) && <List sx={classes.root}>
          {(products || []).map((product) => <ProductListItem key={product.id} item={product} onUpdate={refetch} />)}
        </List>}
    </>
  );
}

function ProductListItem(
  { item, onUpdate }:
    {
      item: IProductData,
      onUpdate?: (i: IProductData) => void
    }
) {
  const labelId = `checkbox-list-label-${item.id}`;
  const theme = useTheme()
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const classes = {
    avatar: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    createButton: {
      float: 'right',
      marginBottom: '1em',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    inline: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      // justifyContent: 'space-evenly'
    }
  }

  const actions = [
    {
      label: 'Edit Product',
      name: 'editProduct',
      roles: ['business'],
      run: (item: IProduct) => {
        ModalComponent.show({
          component: ProductForm,
          title: 'Edit Product',
          noDelay: true,
          passProps: {
            item,
            callback: (newProduct: IProduct) => {
              onUpdate?.(newProduct)
            }
          },
        })
      },
    }, {
      label: 'Open product link',
      name: 'openProductLink',
      run: (item: IProduct) => {
        if (item?.locationUrl) {
          return window.open(item?.locationUrl, '_blank');
        }
        Swal.fire({
          text: 'Product shop url not found',
          icon: 'warning',
          confirmButtonText: 'Ok',
        })
      },
    }, {
      label: 'Delete Product',
      name: 'deleteProduct',
      roles: ['business'],
      run: (item: IProduct) => {
        const { id } = item
        Swal.fire({
          title: 'Are you sure?',
          text: "This product will be deleted permanently. This action cannot be reversed.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed && id) {
            productService.delete(id).then(() => {
              onUpdate?.(item)
            })
          }
        })
      },
    },
  ]
  return (
    <ListItem disableGutters disablePadding sx={{ display: 'block', my:1, borderRadius: '10px', border: '1px solid lightgrey' }}>
      <ListItemButton
        role={undefined}
      >
        <Hidden smDown>
          <ListItemAvatar>
            <Avatar variant='square' sx={classes.avatar} src={item?.thumbUrl} />
          </ListItemAvatar>
        </Hidden>
        <ListItemText
          id={labelId}
          sx={{ paddingRight: 'inherit' }}
          primary={item?.name}
          secondary={<Box component='span' sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
          }}>
            <Box component='span' sx={classes.column}>
              <Typography
                component="span"
                variant="caption"
                color="textPrimary"
              >
                {item?.category?.name}
              </Typography>
              <Typography
                component="span"
                variant="subtitle1"
                color="textSecondary"
              >
                {`Ksh ${item?.price}`}
              </Typography>
              <Typography
                component="span"
                variant="subtitle2"
                color={green[500]}
                sx={classes.inline}
              >
                <PaymentsOutlinedIcon fontSize='small' />&nbsp;
                {`${item.commission}%`} commission on sales
              </Typography>
              <Typography
                component="span"
                variant="subtitle2"
                color="textSecondary"
                onClick={handleClick}
                sx={{ ...classes.inline, mt: 1 }}
              >
                More details... &nbsp;
                {open ? <ExpandLess /> : <ExpandMore />}
              </Typography>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItemText
                    sx={{ whiteSpace: 'pre-line' }}
                    primary={<Typography component='span' variant='body1'>{item?.description} </Typography>} />
                </List>
              </Collapse>
            </Box>

            <Box component='span' sx={{ ...classes.column, minWidth: 'fit-content' }}>
              <Typography
                component="span"
                variant="subtitle2"
                color="textSecondary"
                sx={classes.inline}
              >
                <AccessAlarmsOutlinedIcon fontSize='small' />&nbsp;
                {`Coupon valid for ${item?.cookieLifespan} days`}
              </Typography>
              {item?.sales?.length ? <Typography
                component="span"
                variant="subtitle2"
                color="textSecondary"
                sx={classes.inline}
              >
                <TimelineIcon fontSize='small' />&nbsp;
                {`Last sold ${dayjs(item.sales[0]?.createdAt).format(
                  'LL'
                )}`}
              </Typography> : null}
              <Typography
                component="span"
                variant="subtitle1"
                color="primary"
                sx={classes.inline}
              >
                <DoneAllOutlinedIcon fontSize='small' />&nbsp;
                {item?.sales?.length} successful sales made
              </Typography>

            </Box>

          </Box>} />
        <ListItemSecondaryAction sx={{ position: 'relative' }}>
          <ContextMenu
            items={actions}
            value={item}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
      {/* <Divider /> */}
    </ListItem>
  )
}