import axios from 'axios'
import Swal from 'sweetalert2'
import storage from '../helpers/secure-storage'
import { keys } from '../helpers/constants'
import history from '../helpers/history'

const user = storage.get(keys.USER)

const ApiRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000, // 15 seconds,
})
ApiRequest.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        Swal.fire({
          title: 'Access denied',
          text: "Please login again with the right credentials to make this request",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Login'
        }).then((result) => {
          if (result.isConfirmed) {
            storage.clear()
            history.push('/')
            window.location.reload()
            return null
          }
        })


      }
      if (error.response.status === 403) {
        Swal.fire({
          text: 'Authorization denied',
          icon: 'warning',
          allowOutsideClick: false,
        })
        return Promise.reject(error.response.data)
      }
      return Promise.reject(error.response.data)
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
      return Promise.reject(
        new Error('No response was received from the server')
      )
    }
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)

    return Promise.reject(error)
  }
)
ApiRequest.defaults.headers.common['x-access-token'] =
  user && user.accessToken

export default ApiRequest
