import http from "./api.request";
import BaseDataService from "services/base.service";
import { PaymentMethod } from "types/payment.method.type";

export class PaymentMethodDataService extends BaseDataService {
    constructor() {
        super({ name: 'payment-methods' })
    }

    findByType(type: string) {
        return http.get<Array<PaymentMethod>>(`/payment-methods?name=${type}`);
    }
}

const service = new PaymentMethodDataService();

export default service