import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Typography } from '@mui/material';
import FormInput from 'components/FormInput';
import formatCurrency from 'helpers/formatCurrency';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { number, object, string, TypeOf, union } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ApiRequest from 'services/api.request';
import paymentMethodService from 'services/payment.method.service';
import Swal from 'sweetalert2';
import IPaymentData from 'types/payment.type';

export default function PaymentRequestForm({ onComplete }: { onComplete: (val: IPaymentData) => void }) {
    const { data: paymentMethods } = usePaginatedSearch(paymentMethodService)
    const [balance, setBalance] = useState(0)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        ApiRequest.get('auth/account-balance').then(({ data }) => setBalance(data?.balance || 0))
    }, [])
    const schema = object({
        amount: string().min(1, 'Account holder name is required').max(Math.min(balance, 300000)),
        method: union([string().nullable(), number().nullable()]),
    });
    const defaultValues: { amount: string, method: string | number | null } = {
        amount: '0',
        method: null
    };
    const methods = useForm<TypeOf<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues,
    });



    // 👇 Form Handler
    const onSubmitHandler: SubmitHandler<TypeOf<typeof schema>> = (values: TypeOf<typeof schema>) => {
        setLoading(true)
        const payload = {
            amount: values.amount,
            paymentMethodId: values.method,
            type: 'outgoing'
        }
        ApiRequest.post('payments/', payload).then(({ data }) => {
            Swal.fire({
                text: 'Payment request received.',
                icon: 'info',
            })
            if (onComplete) {
                onComplete(data)
            }
        })
            .catch(error => Swal.fire({
                text: error.error,
                icon: 'error',
            }))
            .finally(() => {
                setLoading(false)
            })
    };
    return (
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
                <Typography paragraph variant='subtitle1'>Current Balance: {formatCurrency(balance)}</Typography>
                <FormInput
                    label='Amount to Withdraw'
                    type='text'
                    name='amount'
                    focused
                    required
                />
                <FormInput
                    select
                    label='Payment Method'
                    name='method'
                    type='text'
                    variant="standard"
                >
                    {(paymentMethods?.rows || []).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {`${(option?.name || '').toUpperCase()} ending in ${option?.ac_no_last_digits}`}
                        </MenuItem>
                    ))}
                </FormInput>

                <LoadingButton
                    loading={loading}
                    type='submit'
                    color='success'
                    variant='contained'
                    sx={{
                        py: '0.8rem',
                        mt: 2,
                        width: '50%',
                        marginInline: 'auto',
                        minWidth: 'fit-content'
                    }}
                >
                    Request Withdrawal
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
