import { forwardRef } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

const headerSX = {
    p: 2.5,
    background: (theme) => theme.palette.primary.main,
    color: 'white',
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const myCard = ({
        border = true,
        boxShadow,
        children,
        content = true,
        contentSX = {},
        darkTitle,
        divider = true,
        elevation,
        secondary,
        shadow,
        sx = {},
        title,
        codeHighlight,
        ...others
    },
    ref
) => {
    return (
        <Card
            elevation={elevation || 0}
            ref={ref}
            {...others}
            sx={{
                ...sx,
                borderRadius: 0,
                '& pre': {
                    m: 0,
                    p: '16px !important',
                    fontSize: '0.75rem'
                }
            }}
        >
            {!darkTitle && title && (
                <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={title} action={secondary} />
            )}
            {darkTitle && title && (
                <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
            )}

            {title && divider && <Divider />}

            {content && <CardContent sx={contentSX}>{children}</CardContent>}
            {!content && children}

        </Card>
    );
}
const MainCard = forwardRef(myCard);



export default MainCard;
