import InvoiceList from 'modules/invoice/invoice.list';
import React from 'react';
import MainLayout from '../layouts/main.layout';

export default function Invoice() {
  return (
    <MainLayout>
      <h2>Invoices</h2>
      <InvoiceList />
    </MainLayout>
  );
}