import axios from 'axios'
import { SignupPayloadType, LoginPayloadType } from 'types/user.type'
import storage from 'helpers/secure-storage'
import { keys } from 'helpers/constants'
import ApiRequest from 'services/api.request'
import { IUser } from 'modules/profile/user.form'

const signup = (payload: SignupPayloadType) => {
  return ApiRequest.post(`/auth/signup`, payload)
}

const login = async (payload: LoginPayloadType) => {
  return await ApiRequest.post(`${process.env.REACT_APP_API_URL}/auth/login`, payload)
}

const verifyPhoneNumber = (username: string, code: string) => {
  /* call api service to verify phone number */
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-phone`, {
    username,
    code,
  })
}

const sendVerificationCode = (username: string) => {
  /* call api service to send verification code to user */
  return username
}

const forgotPassword = (email: string) => {
  /* call api service to send reset password link to user */
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
    email,
  })
}

type ResetPayload = {
  password: string
  userId: string
  token: string
}
const resetPassword = ({ password, userId, token }: ResetPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/auth/reset-password/${userId}/${token}`,
    {
      password,
    }
  )
}

const changePassword = (oldPassword: string, newPassword: string) => {
  /* call api service to change user password */
  return ApiRequest.post(`/auth/change-password`, {
    oldPassword,
    newPassword,
  })
}

const updateProfile = (payload: IUser, id: number | string) => {
  return ApiRequest.put(`/auth/${id}`, payload)
}

const verifyPhoneNumberAndAuthenticate = (
  username: string,
  code: string,
  password: string
) => {
  return verifyPhoneNumber(username, code)
    .then(() => {
      return login({ username, password })
    })
    .catch((error: Error) => {
      throw error
    })
}

const logout = () => {
  return new Promise((resolve, reject) => {
    try {
      console.log('logging out...')
      storage.clear()
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}

const getCurrentUser = () => {
  const user = storage.get(keys.USER)
  return user
}
const authService = {
  signup,
  login,
  logout,
  verifyPhoneNumberAndAuthenticate,
  forgotPassword,
  resetPassword,
  changePassword,
  getCurrentUser,
  sendVerificationCode,
  updateProfile
}

export default authService
