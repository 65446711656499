// @ts-nocheck
import React from 'react'

import { IconButton, Portal, Drawer, Box, Hidden, useTheme } from '@mui/material'
import { Close as IconClose } from '@mui/icons-material'

import Loader from 'components/loader'

const Dispatcher = {
  events: {},
  subscribe(event, callback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  },

  broadcast(event: string, args: undefined) {
    if (this.events[event] && this.events[event].length) {
      this.events[event].map((callback) => {
        return callback(args)
      })
    }
  },
}

export default class DrawerComponent extends React.Component {
  static show(args) {
    Dispatcher.broadcast('showDrawer', args)
  }

  static hide(args) {
    Dispatcher.broadcast('hideDrawer', args)
  }

  static hideAll() {
    Dispatcher.broadcast('hideAllDrawer')
  }

  static replace(args) {
    Dispatcher.broadcast('replaceDrawer', args)
  }

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      queue: [],
    }
  }

  componentDidMount() {
    Dispatcher.subscribe('showDrawer', (args) => {
      let { queue } = this.state
      const { id } = this.state

      if (args.id) {
        if (args.id === id) {
          return false
        }

        const drawerIndex = queue.findIndex((drawer) => drawer.id === args.id)
        if (drawerIndex >= 0) {
          queue = queue.slice(0, drawerIndex)
        }
      }

      queue.push({
        content: <Loader />,
        ...args,
      })

      this.setState({
        show: true,
        queue,
        id: args.id,
        title: args.title,
        className: args.className,
        onCancel: args.onCancel,
      })

      if (!args.content && args.component) {
        setTimeout(
          () => {
            queue.pop()
            queue.push({
              content: <args.component {...args.passProps} />,
              ...args,
            })

            this.setState({
              show: true,
              queue,
            })
          },
          args.noDelay ? 0 : 400
        )
      }
      return null
    })

    Dispatcher.subscribe('hideDrawer', () => {
      this.handleHide()
    })

    Dispatcher.subscribe('hideAllDrawer', () =>
      this.setState({
        show: false,
        queue: [],
      })
    )

    Dispatcher.subscribe('replaceDrawer', (args) => {
      const { queue } = this.state
      queue.pop()
      queue.push(args)
      setTimeout(() => {
        this.setState({
          show: true,
          queue,
          id: args.id,
          onCancel: args.onCancel,
        })
      }, 0)
    })
  }

  handleHide = () => {
    const { onCancel, queue } = this.state
    if (onCancel) {
      onCancel()
    }
    queue.pop()
    if (queue.length > 0) {
      const current = queue[queue.length - 1]
      setTimeout(() => {
        this.setState({
          show: true,
          queue,
          id: current.id,
          onCancel: current.onCancel,
        })
      }, 0)
      return false
    }
    setTimeout(() => {
      this.setState({
        show: false,
        id: null,
        queue: [],
      })
    }, 0)
    return true
  }

  render() {
    const { queue, show, className, title } = this.state

    return (
      <DrawerWrapper show={show} className={`${className || ''}`} title={title}>
        <DrawerHeader onClose={this.handleHide} />
        {queue.map((q, idx) => (
          <div
            key={idx}
            style={{ display: idx === queue.length - 1 ? 'block' : 'none' }}
          >
            {q.content}
          </div>
        ))}
      </DrawerWrapper>
    )
  }
}

const DrawerHeader = ({ onClose }) => {
  return (
    <Box display="flex" flexDirection="row-reverse">
      <IconButton size="small" onClick={onClose}>
        <IconClose height={32} width={32} />
      </IconButton>
    </Box>
  )
}

const DrawerWrapper = (props) => {
  const theme = useTheme()
  const styles = {
    drawerWrapper: {
      display: 'flex',
      padding: theme.spacing(4),
      flexDirection: 'column',
      maxWidth: 800,
    },
    drawerPaper: {
      borderLeft: '6px solid #EEEEF2',
      backgroundColor: '#f1f5f9',
    },
  }
  
  const { show, children } = props
  return (
    <Portal>
      <Hidden smUp>
        <Drawer
          open={show}
          anchor="right"
          variant="temporary"
          PaperProps={{ sx: styles.drawerPaper }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={styles.drawerWrapper}>{children}</Box>
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          open={show}
          anchor="right"
          variant="persistent"
          PaperProps={{ sx: styles.drawerPaper }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={styles.drawerWrapper}>{children}</Box>
        </Drawer>
      </Hidden>
    </Portal>
  )
}
