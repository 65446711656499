import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, MenuItem, Typography, OutlinedInput, InputAdornment, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import MainLayout from '../layouts/main.layout';
import useCategoryList from 'hooks/useCategoryList';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import businessService from 'services/business.service';
import Loader from 'components/loader';
import SaleList from 'modules/sales/sale.list';
import saleService from 'services/sale.service';
// import SalesColumnChart from 'modules/dashboard/SalesColumnChart';
import MainCard from 'components/MainCard';
// import formatCurrency from 'helpers/formatCurrency';
// import { Box } from '@mui/system';

type TProductFilters = {
  businessId?: string;
  categoryId?: string;
  name?: string;
  size?: number;
}

// sales report status
const status = [
  {
    value: 'today',
    label: 'Today'
  },
  {
    value: 'month',
    label: 'This Month'
  },
  {
    value: 'year',
    label: 'This Year'
  }
];

export default function Sales() {
  const { data: businesses } = usePaginatedSearch(businessService)
  const { categories, loading: loadingCategories } = useCategoryList('product')
  const [query, setQuery] = useState<TProductFilters>({ size: 10 })
  const { data: sales, refetch } = usePaginatedSearch(saleService, query || {})
  const [searchText, setSearchText] = useState<string>('')
  const [value, setValue] = useState('today');
  const debouncedSearchTerm = useDebounce(searchText, 500)
  const handleChange = (event: SelectChangeEvent) => {
    const name = event.target.name
    setQuery(state => ({ ...state, [name]: event.target.value }))
  };
  useEffect(() => setQuery(state => ({ ...state, 'name': debouncedSearchTerm })), [debouncedSearchTerm])
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setQuery(state => ({ ...state, page: page - 1 }))
  }

  return (
    <MainLayout>
      <Grid
        container
        display='flex'
        flexDirection='column'
      >
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Sales Report</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="standard-select-currency"
                size="small"
                select
                value={value}
                variant="filled"
                onChange={(e) => setValue(e.target.value)}
                sx={{ '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {/* <MainCard sx={{ mt: 1.75 }}>
            <Stack spacing={1.5} sx={{ mb: -12 }}>
              <Typography variant="h6" color="secondary">
                Earnings
              </Typography>
              <Typography variant="h4">{formatCurrency(1560)}</Typography>
            </Stack>
            <SalesColumnChart />
          </MainCard> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}
            sm={4} sx={{ my: 2 }} >
            <MainCard content={false} title="Filter By" divider>
              <Grid container spacing={2} sx={{
                p: 2,
                display: 'flex',
                flexDirection: { sm: 'row', xs: 'column', md: 'row' },
              }}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" size='small' sx={{ mb: { xs: 1 } }}>
                    <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-search"
                      type='text'
                      name="name"
                      onChange={(event) => setSearchText(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end"> <SearchIcon />
                        </InputAdornment>
                      }
                      label="Search"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl size='small' variant='outlined' fullWidth sx={{ mb: { xs: 1 } }}>
                    <InputLabel id="simple-select-business">Select Business</InputLabel>
                    {!businesses ? <Loader /> : <Select
                      value={query?.businessId}
                      label="Select Business"
                      id="simple-select-business"
                      name="businessId"
                      onChange={handleChange}
                      defaultValue=""
                    >
                      <MenuItem value={''}>---select business---</MenuItem>
                      {(businesses.rows || []).map((row) => (
                        <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                      ))}

                    </Select>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl size='small' variant='outlined' fullWidth sx={{ mb: { xs: 1 } }}>
                    <InputLabel id="simple-select-category">Select Category</InputLabel>
                    {loadingCategories ? <Loader /> : <Select
                      value={query?.categoryId}
                      label="Select Category"
                      id="simple-select-category"
                      name="categoryId"
                      onChange={handleChange}
                      defaultValue=""
                    >
                      <MenuItem value={''}>---select category---</MenuItem>
                      {(categories || []).map((row) => (
                        <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                      ))}

                    </Select>}
                  </FormControl>
                </Grid>

              </Grid>

            </MainCard>

          </Grid>
          {/* <Divider /> */}
          <Grid item xs={12} sm={8} sx={{ columnGap: '1rem', my: 2 }}>
            <MainCard>
              {sales ? <SaleList
                sales={sales.rows}
                pagination={{
                  onPageChange: handlePageChange,
                  count: sales.totalPages,
                  page: sales.currentPage + 1,
                  totalRecords: sales.count
                }} refetch={refetch} /> : null}
            </MainCard>

          </Grid>
        </Grid>

      </Grid>
    </MainLayout>
  );
}