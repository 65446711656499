import { Box, Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Loader from 'components/loader';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import ProductForm from 'modules/product/product.form';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import businessService from 'services/business.service';

export default function NewProduct() {
  const { data: businesses } = usePaginatedSearch(businessService)
  const navigate = useNavigate()
  const [businessId, setBusinessId] = useState<string>("")
  const handleChange = (event: SelectChangeEvent) => {
    setBusinessId((event.target.value) as string);
  };
  return (

    <Box
      justifyContent='space-between'
      sx={{
        // maxWidth: { sm: '45rem' },
        marginInline: 'auto',
      }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Business</InputLabel>
            {!businesses ? <Loader /> : <Select
              value={businessId}
              label="Select Business"
              onChange={handleChange}
              defaultValue=""
            >
              {(businesses.rows || []).map((row) => (
                <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
              ))}

            </Select>}
          </FormControl>


      {businessId ? <Card raised sx={{mt: 2}}>
        <CardHeader title="Product information" />
        <CardContent>
          <ProductForm businessId={Number(businessId)} callback={() => navigate('/products')} />
        </CardContent>
      </Card> : null}


    </Box>
  );
}