import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from 'components/FormInput';
import Swal from 'sweetalert2';
import authService from 'services/auth.service';

// 👇 User Schema with Zod
const userSchema = object({
  firstName: string().min(1, 'First name is required').max(70),
  lastName: string().min(1, 'Last name is required').max(70),
  phone: string().min(1, 'Phone number is required').max(13),
  email: string().min(1, 'Email is required').email('Email is invalid'),
  websiteUrl: string().optional().nullable(),
  socialMediaUrl: string().optional().nullable(),
});

// 👇 Infer the Schema to get TypeScript Type
export interface IUser extends TypeOf<typeof userSchema> {
  id: number
}

const UserForm = ({ user, callback }: {
  user: IUser
  callback?: (i: IUser) => void
}) => {
  const [loading, setLoading] = useState(false)
  const defaultValues: IUser = Object.assign({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    websiteUrl: '',
    socialMediaUrl: '',
  }, user);

  // 👇 Object containing all the methods returned by useForm
  const methods = useForm<IUser>({
    resolver: zodResolver(userSchema),
    defaultValues,
  });

  // 👇 Form Handler
  const onSubmitHandler: SubmitHandler<IUser> = (values: IUser) => {
    setLoading(true)
    authService.updateProfile(values, user?.id || '')
      .then((res) => {
        Swal.fire({
          text: 'Profile saved',
          icon: 'success',
        })
        if (callback) {
          callback({ ...user, ...values })
        }
      })
      .catch((resError) => {
        Swal.fire({
          text: resError?.error || 'Failed to save.',
          icon: 'warning',
          confirmButtonText: 'Try again',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  };


  return (
    <FormProvider {...methods}>
      <Box
        display='flex'
        flexDirection='column'
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={methods.handleSubmit(onSubmitHandler)}
      >
        <FormInput
          label='First Name'
          type='text'
          name='firstName'
          focused
          required
        />
        <FormInput
          label='Last Name'
          type='text'
          name='lastName'
          focused
          required
        />
        <FormInput
          label='Phone Number'
          type='text'
          name='phone'
          helperText="Format: +254XXXXXXXXX"
          focused
          required
        />
        <FormInput
          label='Email'
          type='text'
          name='email'
          focused
          required
        />
        <FormInput
          label='Website'
          type='text'
          name='websiteUrl'
          focused
          required
        />
        <FormInput
          label='Social Media Url'
          type='text'
          name='socialMediaUrl'
          focused
          required
        />

        <LoadingButton
          loading={loading}
          type='submit'
          variant='contained'
          sx={{
            py: '0.8rem',
            mt: 2,
            width: '80%',
            marginInline: 'auto',
          }}
        >
          Save
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

export default UserForm;
