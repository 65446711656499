import { Button } from '@mui/material';
import BusinessList from 'modules/business/business.list';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../layouts/main.layout';

export default function Business() {
  const navigate = useNavigate()
  return (
    <MainLayout>
      <h2>Business</h2>
      <Button variant='contained' sx={{ float: 'right', mb: 1 }} onClick={() => navigate('/business-new')}>Add Business</Button>
      <BusinessList />
    </MainLayout>
  );
}