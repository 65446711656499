import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { AuthContext } from 'App';
import ModalComponent from 'components/Modal';
import formatCurrency from 'helpers/formatCurrency';
import ApiRequest from 'services/api.request';
import PaymentRequestForm from 'modules/settings/payment.request.form';

export default function BalanceCard() {
    const [balance, setBalance] = useState(0)
    const { auth: { id } } = useContext(AuthContext)
    useEffect(() => {
        ApiRequest.get('auth/account-balance')
        .then(({ data }) => setBalance(data?.balance || 0))
        .catch(e => console.error(e))
    }, [id])
    const requestPayClick = () => ModalComponent.show({
        component: PaymentRequestForm,
        title: 'Request for Payment Disbursement',
        noDelay: true,
        passProps: {
            onComplete: (data: any) => {
                ModalComponent.hide()
            }
        },
    })
    return (
        <Card>
            <CardHeader title="Balance" />
            <CardContent>
                <Box display={'flex'} justifyContent="space-between">
                    <Typography variant='body1'>Available balance is <b>{formatCurrency(balance)}</b></Typography>
                    <Button variant='contained' onClick={requestPayClick} disabled={balance < 200 }>Get paid now</Button>
                </Box>
            </CardContent>
        </Card>
    );
}