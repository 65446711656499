import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import businessService from 'services/business.service';
import Swal from 'sweetalert2';
import BusinessForm from './business.form';
import ModalComponent from 'components/Modal';
import IBusinessData from 'types/business.type';
import usePaginatedSearch from 'hooks/usePaginatedSearch';
import Loader from 'components/loader'
import { Link } from 'react-router-dom';

export default function BusinessList() {
  const { data, loading, error, refetch } = usePaginatedSearch(businessService)

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error getting data</AlertTitle>
        {error}
      </Alert>
    )
  }
  if (loading) {
    return <Loader />
  }

  if (!data) {
    return (
      <Typography variant='subtitle1'>Data not available yet</Typography>
    )
  }

  const { rows } = data

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This business will be deleted with all the products associated with it. This action cannot be revesed.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        businessService.delete(id).then(() => {
          refetch()
        })
      }
    })
  }
  const handleEditClick = (item: IBusinessData) => {
    ModalComponent.show({
      component: BusinessForm,
      title: 'Edit Business',
      noDelay: true,
      passProps: {
        item,
      },
    })
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="business table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Category</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link to={`/business/${row.id}`}>{row.name}</Link>
              </TableCell>
              <TableCell align="right">{row?.category?.name}</TableCell>
              <TableCell align="right">{row.phone}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.address}</TableCell>

              <TableCell align="right" sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button variant='contained' onClick={() => handleDelete(row.id)} color='error'>Delete</Button>
                <Button variant='outlined' onClick={() => handleEditClick(row)}>Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}