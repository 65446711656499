import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from 'components/FormInput';
import Swal from 'sweetalert2';
import authService from 'services/auth.service';
import { useNavigate } from 'react-router-dom';

// 👇 User Schema with Zod
const validationSchema = object({
  oldPassword: string().min(1, 'Old password is required'),
  newPassword: string()
    .min(1, 'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  passwordConfirm: string().min(1, 'Please confirm your password')
}).refine((data) => data.newPassword === data.passwordConfirm, {
  path: ['passwordConfirm'],
  message: 'Passwords do not match',
});

// 👇 Infer the Schema to get TypeScript Type
export interface IUser extends TypeOf<typeof validationSchema> {
  id: number
}

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const defaultValues: TypeOf<typeof validationSchema> = {
    oldPassword: '',
    newPassword: '',
    passwordConfirm: ''
  }

  // 👇 Object containing all the methods returned by useForm
  const methods = useForm<TypeOf<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  // 👇 Form Handler
  const onSubmitHandler: SubmitHandler<TypeOf<typeof validationSchema>> = (values: TypeOf<typeof validationSchema>) => {
    console.log(values.oldPassword, values.newPassword)
    setLoading(true)
    authService.changePassword(values.oldPassword, values.newPassword)
      .then((res) => {
        Swal.fire({
          title: 'Password updated',
          text: 'Please login again using the new password',
          icon: 'success',
          allowOutsideClick: false,
          confirmButtonText: 'Login'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/')
            authService.logout()
            window.location.reload()
          }
        })
      })
      .catch((resError) => {
        Swal.fire({
          text: resError?.error || 'Password change failed.',
          icon: 'warning',
          confirmButtonText: 'Try again',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  };


  return (
    <FormProvider {...methods}>
      <Box
        display='flex'
        flexDirection='column'
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={methods.handleSubmit(onSubmitHandler)}
      >
        <FormInput
          label='Old Password'
          type='password'
          name='oldPassword'
          focused
          required
        />
        <FormInput
          label='New Password'
          type='password'
          name='newPassword'
          focused
          required
        />
        <FormInput
          label='Confirm Password'
          type='password'
          name='passwordConfirm'
          focused
          required
        />

        <LoadingButton
          loading={loading}
          type='submit'
          variant='contained'
          sx={{
            py: '0.8rem',
            mt: 2,
            width: '80%',
            marginInline: 'auto',
          }}
        >
          Change Password
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

export default ChangePassword;
