import http from "services/api.request";
import ICategoryData from "types/category.type";

export class CategoryDataService {

    findByType(type: string) {
        return http.get<Array<ICategoryData>>(`/categories?type=${type}`);
    }
}

const service = new CategoryDataService();

export default service