import AccountProfile from 'modules/profile';
import React from 'react';
import MainLayout from '../layouts/main.layout';

export default function Account() {
  return (
    <MainLayout>
      <h2>My Profile</h2>
      <AccountProfile />
    </MainLayout>
  );
}