import { Box, Button, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { number, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from 'components/FormInput';
import businessService from 'services/business.service';
import Swal from 'sweetalert2';
import useCategoryList from 'hooks/useCategoryList';

// 👇 SignUp Schema with Zod
const businessSchema = object({
    name: string().min(1, 'Business name is required').max(70),
    categoryId: number().min(1, 'Category is required'),
    address: string().min(1, 'Address is required').max(100),
    phone: string().min(11, 'Address is required'),
    email: string().email(),
    taxPin: string(),
});

// 👇 Infer the Schema to get TypeScript Type
export interface IBusiness extends TypeOf<typeof businessSchema> {
    id?: number | string
}

type Field = "name" | "categoryId" | "email" | "phone" | "address" | "taxPin" | "id"

const BusinessForm = ({ item, onSubmit, onCancel }: {
    item?: IBusiness
    onSubmit?: () => void
    onCancel?: () => void
}) => {
    const [loading, setLoading] = useState(false)
    const { categories, loading: loadingCategories } = useCategoryList('business')
    // 👇 Default Values
    const defaultValues: IBusiness = item || {
        name: '',
        categoryId: 0,
        phone: '',
        email: '',
        taxPin: '',
        address: '',
    };

    // 👇 Object containing all the methods returned by useForm
    const methods = useForm<IBusiness>({
        resolver: zodResolver(businessSchema),
        defaultValues,
    });

    // 👇 Form Handler
    const onSubmitHandler: SubmitHandler<IBusiness> = (values: IBusiness) => {
        setLoading(true)
        const action = item?.id ? businessService.update : businessService.create
        action(values, item?.id || '')
            .then(() => {
                methods.reset()
                Swal.fire({
                    text: 'Business saved',
                    icon: 'success',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                })
                onSubmit?.()
            })
            .catch((resError) => {
                Swal.fire({
                    text: resError?.error || 'Failed to save.',
                    icon: 'warning',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Try again',
                    allowOutsideClick: false,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const updateNumeric = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        methods.setValue(evt.currentTarget.name as Field, parseInt(evt.currentTarget.value))
    }

    return (
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
                <FormInput
                    label='Business Name'
                    type='text'
                    name='name'
                    focused
                    required
                />
                {loadingCategories ? <div>Fetching categories...</div> : <FormInput
                    select
                    label='Category'
                    name='categoryId'
                    onChange={updateNumeric}
                    SelectProps={{
                        native: true,
                    }}
                    variant="standard"
                >
                    {categories.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </FormInput>}
                <FormInput
                    label='Contact email'
                    type='email'
                    name='email'
                    focused
                    required
                />
                <FormInput
                    label='Contact Phone Number'
                    type='text'
                    name='phone'
                    placeholder='+254712345678'
                    focused
                    required
                />
                <FormInput
                    label='Address'
                    type='text'
                    multiline
                    rows={4}
                    name='address'
                    focused
                    required
                />
                <FormInput
                    type='text'
                    label='Tax PIN'
                    name='taxPin'
                    placeholder='eg A*********M'
                    focused
                />
                <Stack direction={'row'} spacing={2}>
                    <Button onClick={onCancel}>Cancel</Button>
                    <LoadingButton
                        loading={loading}
                        type='submit'
                        variant='contained'
                    >
                        Save
                    </LoadingButton>
                </Stack>


            </Box>
        </FormProvider>
    );
};

export default BusinessForm;
