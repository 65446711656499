import React, { ReactNode, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Collapse,
  Container,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export interface IItem {
  constructor?: unknown
  name?: string
  path?: string
  label?: string
  Icon?: ReactNode
  items?: IItem[]
  disabled?: boolean
  roles?: string[]
  onClick?: () => void
}
function hasChildren(item: IItem) {
  const { items: children } = item

  if (children === undefined) {
    return false
  }

  if (children.constructor !== Array) {
    return false
  }

  if (children.length === 0) {
    return false
  }

  return true
}

const MultiLevel = ({ item }: IMenuItem) => {
  const { items: children } = item

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          disableRipple
          onClick={handleClick}
          disabled={Boolean(item?.disabled)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: 'center',
              color: '#fff'
            }}
          >
            {item?.Icon}
          </ListItemIcon>
          <ListItemText primary={item?.label} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </ListItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{ display: { xs: 'none', md: 'block' } }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(children || []).map((child: IItem, key) => (
          <MenuItem key={key} item={child} />
        ))}
      </Menu>
      <Collapse sx={{ display: { xs: 'block', md: 'none' } }} in={open} timeout="auto" unmountOnExit>
        <Container>
          {(children || []).map((child: IItem, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </Container>
      </Collapse>
    </>
  )
}

const SingleLevel = ({ item }: IMenuItem) => {
  const { path, Icon, label, onClick } = item
  const location = useLocation()
  const navigate = useNavigate()
  const active = path ? location.pathname === path : false
  const openUrl = useCallback(() => onClick ? onClick() : (path ? navigate(path) : null), [onClick, navigate, path])

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        onClick={openUrl}
        disabled={active}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: 'center',
            color: 'inherit'
          }}
        >
          {Icon}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  )
}
interface IMenuItem {
  item: IItem
}

const MenuItem = ({ item }: IMenuItem) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel
  return <Component item={item} />
}

export default MenuItem
