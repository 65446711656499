import { createContext, useCallback, useEffect, useState } from 'react';
import { CssBaseline, PaletteMode, useMediaQuery } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga'

import LoginPage from 'pages/login.page';
import SignupPage from 'pages/signup.page';
// import Dashboard from 'pages/dashboard.page';
import Business from 'pages/business.page';
import history from 'helpers/history';
import { publicUrls } from 'helpers/constants';
import Invoices from 'pages/invoices.page';
import Sales from 'pages/sales.page';
import Payments from 'pages/payments.page';
import Products from 'pages/products.page';
import AddBusiness from 'pages/add.business.page';
import DrawerComponent from 'components/DrawerComponent';
import ModalComponent from 'components/Modal';
import PageNotFound from 'pages/notfound.page';
import './main.css'
import Account from 'pages/account.page';
import PaymentMethods from 'pages/payment.methods.page';
import { User } from 'types/user.type';
import storage from 'helpers/secure-storage'
import { keys } from 'helpers/constants'
import ViewBusiness from 'pages/view.business.page';
import ReportOverview from 'modules/reports/report.overview';

import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import getDesignTokens from 'theme';

const currentTheme = (mode: PaletteMode) => createTheme(getDesignTokens(mode));

history.listen((location: any) => {
  ReactGA.set({ page: location?.pathname })
  ReactGA.pageview(location?.pathname)
})

export const AuthContext = createContext<any>({})
const getUser = async () => {
  const user = await storage.get(keys.USER);
  return user
};

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [user, setUser] = useState<User | null>(null);
  const token = user?.accessToken
  const role = user?.currentRole || ''
  const mode = useCallback(() => currentTheme(isMobile && !publicUrls.includes(pathname) ? 'dark' : 'light'), [isMobile, pathname])

  useEffect(() => {
    getUser().then(res => {
      setUser(res)
    })
  }, [])

  const setAuth = useCallback((user: User | null) => {
    storage.set(keys.USER, user);
    setUser(user);
  }, [])


  useEffect(() => ReactGA.pageview(pathname), [pathname])
  useEffect(() => {
    if (token && publicUrls.includes(pathname)) {
      navigate('/')
    }
  }, [navigate, pathname, token])

  if (!token && !publicUrls.includes(pathname)) {
    return (<LoginPage />)
  }


  return (
    <ThemeProvider theme={mode()}>
      <AuthContext.Provider value={{ auth: user, setAuth }}>
        <CssBaseline />
        <Routes>
          <Route path='/' element={<Products />} />
          <Route path='/signup' element={<SignupPage />} />
          {['business'].includes(role) && <Route path='/business' element={<Business />} />}
          {['business'].includes(role) && <Route path='/business/:id' element={<ViewBusiness />} />}
          {['business', 'affiliate'].includes(role) && <Route path='/products' element={<Products />} />}
          {['affiliate'].includes(role) && <Route path='/payments' element={<Payments />} />}
          {['business'].includes(role) && <Route path='/invoices' element={<Invoices />} />}
          {['business'].includes(role) && <Route path='/business-new' element={<AddBusiness />} />}
          {['affiliate'].includes(role) && <Route path='/settings/disbursement-methods' element={<PaymentMethods />} />}
          {['business', 'affiliate', 'admin'].includes(role) && <Route path='/profile' element={<Account />} />}
          {['affiliate'].includes(role) && <Route path='/reports/overview' element={<ReportOverview />} />}
          {['business', 'affiliate'].includes(role) && <Route path='/reports/sales/chart' element={<Sales />} />}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <DrawerComponent />
        <ModalComponent />
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
